export const getTimestampString = () => {
    let d = new Date();
    let month = d.toLocaleString('default', { month: 'long' });
    let m = d.getMonth();
    if (m < 10) {
        m = "0" + m;
    }
    let day = d.getDate();
    if (d < 10) {
        d = "0" + d;
    }
    let year = d.getFullYear();
    let newD = month + " " + day + ", " + year;

    let h = d.getHours();
    if (h < 10) {
        h = "0" + h;
    }
    let mins = d.getMinutes();
    if (mins < 10) {
        mins = "0" + mins;
    }
    let s = d.getSeconds();
    if (s < 10) {
        s = "0" + s;
    }
    let newOrderedDate = year + "" + m + "" + day + h + "" + mins + "" + s;
    return [newD, newOrderedDate];
}