import { Container, Col, Row, Carousel, Button, Image } from 'react-bootstrap';
import styled from 'styled-components';
//import barneyImg from '../assets/carousel/barney.png';
//import emoImg from '../assets/carousel/emo.png';
import hattieImg from '../assets/carousel/hattie.png';
//import idaImg from '../assets/carousel/ida.png';
import shirleyImg from '../assets/carousel/shirley.png';
//import tcImg from '../assets/carousel/tc.png';
import sharestory from '../assets/share-story.jpg';
import photoalbum from '../assets/photo-album.jpg';
import sharing from '../assets/sharing-2.jpg';

const Styles = styled.div`
    .welcomeMessage {
        font-family: 'Poiret One';
        font-size: 30px;
    }

    .welcomeMessage div.middle {
        font-size: 52px;
        font-weight: bold;
    }

    .carousel {
        border: 3px solid rgb(14, 91, 99);
        border-radius: 5px;
    }

    .carousel-indicators {
        display: none;
    }
    
    .carousel-control-prev, .carousel-control-next {
        opacity: .7;
    }
    .carousel-control-prev:hover, .carousel-control-next:hover {
        opacity: 1;
    }

    .carousel-control-next-icon {
        height: 25px;
        width: 25px;
        background-color: rgb(14, 91, 99);
        border-radius: 50%;
        display: inline-block;
        background-size: 50%;
    }

    .carousel-control-prev-icon {
        height: 25px;
        width: 25px;
        background-color: rgb(14, 91, 99);
        border-radius: 50%;
        display: inline-block;
        background-size: 50%;
        background-position-x: 40%;
    }

    .jumbo {
        background: url(${sharing}) no-repeat center;
        background-size: cover;
        height: 600px;
        position: relative;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .jumboOverlay {
        background-color: rgba(14, 91, 99, .7);
        border-radius: 5px;
        position: relative;
        top: 70px;
        padding: 20px;
        color: white;
    }

    // Top shape
    .custom-shape-divider-bottom-1629284986 {
        position: absolute;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        margin-top: 50px;
    }
    
    .custom-shape-divider-bottom-1629284986 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 50px;
        transform: rotateY(180deg);
    }
    
    .custom-shape-divider-bottom-1629284986 .shape-fill {
        fill: #E6E6E6;
    }

    //Bottom Shape
    .custom-shape-divider-top-1629160771 {
        position: absolute;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        margin-top: 100px;
        margin-bottom: 100px;
    }
    
    .custom-shape-divider-top-1629160771 svg {
        position: relative;
        display: block;
        width: calc(129% + 1.3px);
        height: 60px;
    }
    
    .custom-shape-divider-top-1629160771 .shape-fill {
        fill: #E6E6E6;
    }

    .sectionAfterDivider {
        margin-top: 100px;
    }

    .sectionAfterDivider2 {
        margin-bottom: 150px;
    }

    h2.fancy {
        font-family: 'Poiret One';
        font-size: 30px;
        font-weight: bold;
    }

    .code {
        font-family: Courier;
        font-size: 12px;
        display: block;
        padding-left: 20px;
        padding-top: 20px;
    }

    .homeImage {
        border-radius: 10px;
    }

    .container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .topPadding {
        padding-top: 50px;
    }
    
    /* Mobile-only styles */
    @media (max-width: 376px) {
        .container {
            padding-top: 0px;
            padding-bottom: 50px;
        }
        
        .bottomPadding {
            padding-bottom: 10px !important;
        }

        .welcomeMessage {
            font-family: 'Poiret One';
            font-size: 24px;
        }
    
        .welcomeMessage div.middle {
            font-size: 36px;
            font-weight: bold;
        }

        h2.fancy {
            font-family: 'Poiret One';
            font-size: 28px;
            font-weight: bold;
        }

        .code {
            font-family: Courier;
            font-size: 10.5px;
            display: block;
            padding-left: 10px;
            padding-top: 10px;
        }

        .jumbo {
            background: url(${sharing}) no-repeat center;
            background-size: cover;
            height: 600px;
            position: relative;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
`;

export const Home = (props) => {
    return (
        <Styles>
            <Container>
                <Row>
                    <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
                        <h1 className="welcomeMessage">Create a<div className="middle">Free Memorial Website</div>to Remember Your Loved One</h1>
                        <p>
                            Celebrate the life of your loved one with a free memorial website from Tribute Memorials! We make it easy for you to quickly setup a truly personalized website that you can share with family & friends.
                        </p>
                        <p>
                            Share photos, tell stories and then share it with loved ones. It only takes a few minutes to get started!
                        </p>
                        <Button className="tributeButton">Build a Site &gt;</Button>
                    </Col>
                    <Col xs={{span: 12, order: 1}} md={{span: 6, order: 2}} className="bottomPadding">
                        <Carousel nextLabel="" prevLabel="" pause="false" fade="true">
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={hattieImg}
                                    alt="Hattie Kelley"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={shirleyImg}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            {/*
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={idaImg}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={emoImg}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={barneyImg}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={5000}>
                                <img
                                    className="d-block w-100"
                                    src={tcImg}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            */}
                        </Carousel>
                    </Col>
                </Row>

                <div className="custom-shape-divider-bottom-1629284986">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>
            </Container>

            <Container className="sectionAfterDivider">
                <Row xs={1} md={2}>
                    <Col className="bottomPadding">
                        <Image src={sharestory} alt="Grandma shares a story with the grandkids" fluid className="homeImage" />
                    </Col>
                    <Col>
                        <h2 className="fancy">Share the Stories that Matter</h2>
                        <p>So many memories and stories to tell. Share your loved one's life with the world with a free site from Tribute Memorials.</p>
                        <p>Build a beautiful website in minutes. Our easy-to-use tools allow you to build a stellar website quickly to commemorate your loved one's life.</p> 
                        <p>Choose from dozens of fonts, icons and page layouts. Then, upload your own text and photos to build a site in which you can be proud.</p>
                        <p>All sites are easy to share. We give you a free, secure web address that you can share. Here is an example: <span className="code">https://tributememorials.com/[Your Loved One's Name]</span></p>
                        <Button className="tributeButton">Get Started &gt;</Button>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="jumbo">
                <Container>
                    <Row>
                        <Col className="jumboOverlay">
                            <h2 className="fancy">Get Everyone Involved</h2>
                            <p>One of the best features of a Tribute Memorial is the ability to share your loved one's tribute site with your friends and family.</p>
                            <p>Then, there is the virtual guest book. This part of your site allows visitors to leave a message about your loved one right on your site for everyone to see.</p>
                            <p>Your site also offers you an easy way to share content from your site on FaceBook, Twitter and other social media sites.</p>
                            <Button className="tributeButton">Get Started &gt;</Button>
                        </Col>
                        <Col xs={0} sm={0} md={1}>&nbsp;</Col>
                    </Row>
                </Container>
            </Container>

            <Container className="sectionAfterDivider2">
                <Row xs={1} md={2}>
                    <Col className="bottomPadding">
                        <Image src={photoalbum} alt="Share photos of your loved one with the world" fluid className="homeImage" />
                    </Col>
                    <Col>
                        <h2 className="fancy">Memories All in One Place</h2>
                        <p>Your Tribute Memorials website also comes with the ability for you to upload photos of your loved one into our Photo Album.</p>
                        <p>Easily drag-and-drop images of your beloved onto your site, and they will appear for you to share with the world.</p> 
                        <p>The photo album then allows visitors to share their name and a comment on each photo.</p>
                        <p>All comments are stored and can be managed by you in the admin area of your website.</p>
                        <p>A tribute memorials website is a great way to remember your loved one. Try it out for free today!</p>
                        <Button className="tributeButton">Get Started &gt;</Button>
                    </Col>
                </Row>

                <div className="custom-shape-divider-top-1629160771">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>
            </Container>

            
        </Styles>
    );
}