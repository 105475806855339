import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import 'firebase/auth';
import 'firebase/firestore';
import { firestoreDb } from '../../firebase/firebaseConfiguration';

export const EditTribute = (props) => {
    const {currentUser} = props;
    const [tributeFirstName, setTributeFirstName] = useState("");
    const [tributeMiddleName, setTributeMiddleName] = useState("");
    const [tributeLastName, setTributeLastName] = useState("");
    const [tributeDateOfBirth, setTributeDateOfBirth] = useState("");
    const [tributeDateOfPassing, setTributeDateOfPassing] = useState("");
    const [tributePersonOrPet, setTributePersonOrPet] = useState("person");
    const [tributeGender, setTributeGender] = useState("male");
    const [tributeUrl, setTributeUrl] = useState("");
    const [originalTributeUrl, setOriginalTributeUrl] = useState("");
    const [validated, setValidated] = useState(false);
    const [titleType, setTitleType] = useState("Create");
    const [doesSiteNameExist, setDoesSiteNameExist] = useState(false);
    const [validationClassName, setValidationClassName] = useState("");

    let history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        if (currentUser) {
            handleReset();
            let tsites = [];
            const tributeSiteCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites`);
            tributeSiteCollection.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let d = doc.data();
                    d.id = doc.id;
                    tsites.push(d);
                });

                if (id && tsites.length > 0) {
                    var siteToEdit = tsites.filter(obj => {
                        return obj.id === id
                    });
        
                    if (siteToEdit && siteToEdit.length > 0) {
                        siteToEdit = siteToEdit[0];
                        setTributeFirstName(siteToEdit.tributeFirstName);
                        setTributeMiddleName(siteToEdit.tributeMiddleName);
                        setTributeLastName(siteToEdit.tributeLastName);
                        setTributeDateOfBirth(siteToEdit.tributeDateOfBirth);
                        setTributeDateOfPassing(siteToEdit.tributeDateOfPassing);
                        setTributePersonOrPet(siteToEdit.tributePersonOrPet);
                        setTributeGender(siteToEdit.tributeGender);
                        setTributeUrl(siteToEdit.tributeUrl);
                        setOriginalTributeUrl(siteToEdit.tributeUrl);
                        setTitleType("Edit");
                    }
                }
            });
        }
        
    }, [currentUser, id]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidationClassName("was-validated");

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(false);
        } else {
            // See if the site exists already
            var siteRef = firestoreDb.collection(`sites`).doc(tributeUrl);
            siteRef.get().then((site) => {
                if (site.exists) {
                    let siteData = site.data();
                    if (siteData.userId === currentUser.uid && titleType === "Edit" && tributeUrl === originalTributeUrl) {
                        // It's the same user who owns this site and they are editing, so go forward
                        setValidated(true);
                        createTributeSite();
                    } else {
                        // Not the same user, so tell them that username already exists
                        // There is already a name with this url, tell them to pick another
                        event.stopPropagation();
                        setValidated(false);
                        setDoesSiteNameExist(true);
                    }
                } else {
                    // Name doesn't exist, so go ahead and add it
                    setValidated(true);
                    createTributeSite();
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
                event.stopPropagation();
                setValidated(false);
            });
        }
    };

    const handleReset = () => {
        setTributeFirstName("");
        setTributeLastName("");
        setTributeMiddleName("");
        setTributeDateOfBirth("");
        setTributeDateOfPassing("");
        setTributePersonOrPet("");
        setTributeGender("");
        setTributeUrl("");
        setValidated(false);
      };

    const getRandomTemplate = (max) => {
        return Math.floor(Math.random() * max) + 1;
    }
    const createTributeSite = () => {
        setValidationClassName("");
        
        if (currentUser) {
            const tributeSite = {
                tributeFirstName,
                tributeLastName,
                tributeMiddleName,
                tributeDateOfBirth,
                tributeDateOfPassing,
                tributePersonOrPet,
                tributeGender,
                tributeUrl,
                "template": getRandomTemplate(3)
            };
            
            if (titleType === "Create") {
                // Create the site
                const tributeSiteCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites`);
                tributeSiteCollection.add(tributeSite).then((docRef) => {
                    // Now, create the default pages
                    let docId = docRef.id;
                    
                    let homePage = {
                        "pageId": "home",
                        "pageTitle": "Home",
                        "pageContent": ""
                    };
                    addPage(homePage, docId, "Home");

                    let guestBookPage = {
                        "pageId": "guestBook",
                        "pageTitle": "Guest Book",
                        "pageContent": ""
                    };
                    addPage(guestBookPage, docId, "Guest Book");

                    let photoAlbumPage = {
                        "pageId": "photoAlbum",
                        "pageTitle": "Photo Album",
                        "pageContent": ""
                    };
                    addPage(photoAlbumPage, docId, "Photo Album");

                    let ep1Page = {
                        "pageId": "ep1",
                        "pageTitle": "About",
                        "pageContent": ""
                    };
                    addPage(ep1Page, docId, "Extra Page 1");

                    let ep2Page = {
                        "pageId": "ep2",
                        "pageTitle": "Contact",
                        "pageContent": ""
                    };
                    addPage(ep2Page, docId, "Extra Page 2");

                    // Add this site to the sites table
                    let hotsite = {
                        "siteId": docId,
                        "userId": currentUser.uid,
                        "firstName": tributeFirstName,
                        "lastName": tributeLastName,
                        "tributeUrl": tributeUrl
                    }
                    
                    firestoreDb.collection(`sites`).doc(tributeUrl).set(hotsite).then((hotSiteRef) => {
                        history.push("/secure/list-tributes");
                    }).catch((error) => {
                        console.log("There was an error writing the tribute site to the hotsite store: ", error);
                    });
                }).catch((error) => {
                    console.log("There was an error writing the tribute site to the store: ", error);
                });
            } else {
                const tributeSiteDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}`);
                tributeSiteDocument.update(tributeSite).then(() => {
                    // See if there is an originalSiteUrl, if so, we need to update the existing one in the sites table
                    if (originalTributeUrl !== tributeUrl) {
                        // Create a new site from the new tributeUrl
                        let hotsite = {
                            "siteId": id,
                            "userId": currentUser.uid,
                            "firstName": tributeFirstName,
                            "lastName": tributeLastName,
                            "tributeUrl": tributeUrl
                        }
                        
                        firestoreDb.collection(`sites`).doc(tributeUrl).set(hotsite).then((hotSiteRef) => {
                            // Get all of the guestbookEntries from the old site and move them to this object
                            firestoreDb.collection(`sites/${originalTributeUrl}/guestBookEntries`).get().then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    let data = doc.data();

                                    let guestBookEntry = {
                                        "name": data.name,
                                        "comment": data.comment,
                                        "date": data.date,
                                        "orderByDate": data.orderByDate
                                    }

                                    // Add new guest book entry to new site
                                    let guestBookCollection = firestoreDb.collection(`sites/${tributeUrl}/guestBookEntries`);
                                    guestBookCollection.add(guestBookEntry).then((gbRef) => {
                                    }).catch((error) => {
                                        console.log("There was an moving your guest book entries: ", error);
                                    });

                                    // Delete this guestbook entry from the old url
                                    firestoreDb.doc(`sites/${originalTributeUrl}/guestBookEntries/${doc.id}`).delete().then(() => {
                                    }).catch((error) => {
                                        console.log("Old guest book entry not deleted: ", doc.id);
                                        console.log(error);
                                    });
                                });
                            });

                            // Get all of the photoComments from the old site and move them to this object
                            firestoreDb.collection(`sites/${originalTributeUrl}/photoComments`).get().then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    let data = doc.data();

                                    let photoCommentEntry = {
                                        "name": data.name,
                                        "comment": data.comment,
                                        "date": data.date,
                                        "orderByDate": data.orderByDate,
                                        "photoId": data.photoId
                                    }
                        
                                    // Add new photo comment to new site
                                    let photoCommentCollection = firestoreDb.collection(`sites/${tributeUrl}/photoComments`);
                                    photoCommentCollection.add(photoCommentEntry).then((pcRef) => {
                                    }).catch((error) => {
                                        console.log("There was an error moving your photo comments: ", error);
                                        console.log(error);
                                    });

                                    // Delete this photo comment from the old url
                                    firestoreDb.doc(`sites/${originalTributeUrl}/photoComments/${doc.id}`).delete().then(() => {
                                    }).catch((error) => {
                                        console.log("Old photo comment not deleted: ", doc.id);
                                        console.log(error);
                                    });
                                });
                            });

                            // Delete the old site collection
                            firestoreDb.doc(`sites/${originalTributeUrl}`).delete().then(() => {
                            }).catch((error) => {
                                console.error("Error removing site: ", originalTributeUrl);
                                console.log(error);
                            });
                        }).catch((error) => {
                            console.log("There was an error writing the tribute site to the hotsite store: ", error);
                        });
                    } else {
                        // Create a new site from the new tributeUrl
                        let hotsite = {
                            "siteId": id,
                            "userId": currentUser.uid,
                            "firstName": tributeFirstName,
                            "lastName": tributeLastName,
                            "tributeUrl": tributeUrl
                        }
                        
                        firestoreDb.collection(`sites`).doc(tributeUrl).set(hotsite).then((hotSiteRef) => {
                        });
                    }

                    history.push("/secure/list-tributes");
                }).catch((error) => { 
                    console.log("There was an error updating the tribute site in the store: ", error)
                });
            }
        }
    }

    const addPage = (pageToSave, docId, title) => {
        const pagesCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites/${docId}/pages`);
        pagesCollection.add(pageToSave)
        .then((docRef) => {
        })
        .catch((error) => {
            console.error("There was an error saving the default pages: ", error);
        });
    }

    const renderContent = () => {
        if (currentUser === null) {
            return (<Redirect to={{ pathname: '/secure/signin' }} />);
        } else if (!currentUser.emailVerified) {
            return (<Redirect to={{ pathname: '/secure/verify-email' }} />);
        } else {
            return (
                <Form id="createTributeForm" noValidate validated={validated} className={validationClassName} onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <h1 className="fancy">{ titleType } a Tribute Site</h1>
                            <p>To { titleType } a tribute site, just fill in all of the required fields below (those marked with an asterisk *). Then, click the { titleType } Tribute button.</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <fieldset>
                                <legend>Your Loved One's Name</legend>
                                <Row xs={1} md={3}>
                                    <Col>
                                        <Form.Group controlId="tributeFirstName">
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control type="text" value={tributeFirstName} required placeholder="Loved one's first name" onChange={e => setTributeFirstName(e.target.value.replace(/[^a-zA-Z0-9\-'\s\d]/ig, ""))} />
                                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">Please enter your loved one's first name. Only letters, spaces, numbers, hyphens and apostrophe are accepted.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="tributeMiddleName">
                                            <Form.Label>Middle Name</Form.Label>
                                            <Form.Control type="text" value={tributeMiddleName} placeholder="Loved one's middle name" onChange={e => setTributeMiddleName(e.target.value.replace(/[^a-zA-Z0-9\-'\s\d]/ig, ""))} />
                                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="tributeLastName">
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control type="text" value={tributeLastName} required placeholder="Loved one's last name" onChange={e => setTributeLastName(e.target.value.replace(/[^a-zA-Z0-9\-'\s\d]/ig, ""))} />
                                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">Please enter your loved one's last name. Only letters, spaces, numbers, hyphens and apostrophe are accepted.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Text id="tributeUrlHelpText" muted>
                                            Only letters, spaces, numbers, hyphens and apostrophe characters are allowed in name fields.
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <fieldset>
                                <legend>Your Loved One's Life</legend>

                                <Row>
                                    <Col>
                                        <Form.Group controlId="tributeDateOfBirth">
                                            <Form.Label>Date of Birth*</Form.Label>
                                            <Form.Control type="date" required placeholder="01/01/1900" value={tributeDateOfBirth} onChange={e => setTributeDateOfBirth(e.target.value)} />
                                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">Please enter your loved one's date of birth.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="tributeDateOfPassing">
                                            <Form.Label>Date of Passing*</Form.Label>
                                            <Form.Control type="date" required placeholder="01/01/2021" value={tributeDateOfPassing} onChange={e => setTributeDateOfPassing(e.target.value)} />
                                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">Please enter your loved one's date of passing.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <fieldset>
                                <legend>Tribute Site Information</legend>

                                <Row>
                                    <Col>
                                        <Form.Label>Is this website for a person or a pet?*</Form.Label>
                                        <Form.Group controlId="tributePersonOrPetGroup">
                                            <Form.Check
                                                inline
                                                label="Person"
                                                name="tributePersonOrPet"
                                                type="radio"
                                                id="tributePerson"
                                                required
                                                defaultChecked={tributePersonOrPet === "person"}
                                                onClick={e => setTributePersonOrPet("person")}
                                            />
                                            <Form.Check
                                                inline
                                                label="Pet"
                                                name="tributePersonOrPet"
                                                type="radio"
                                                id="tributePet"
                                                required
                                                defaultChecked={tributePersonOrPet === "pet"}
                                                onClick={e => setTributePersonOrPet("pet")}
                                            />
                                        </Form.Group>
                                        
                                        <br />

                                        <Form.Label>Gender?*</Form.Label>
                                        <Form.Group controlId="tributeGenderGroup">
                                            <Form.Check
                                                inline
                                                label="Male"
                                                name="tributeGender"
                                                type="radio"
                                                id="tributeGenderMale"
                                                required
                                                defaultChecked={tributeGender === "male"}
                                                onClick={e => setTributeGender("male")}
                                            />
                                            <Form.Check
                                                inline
                                                label="Female"
                                                name="tributeGender"
                                                type="radio"
                                                id="tributeGenderFemale"
                                                required
                                                defaultChecked={tributeGender === "female"}
                                                onClick={e => setTributeGender("female")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label htmlFor="tributeUrlField">Tribute Site Location (URL)</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon3">https://tributememorials.com/remember/</InputGroup.Text>
                                                <Form.Control id="tributeUrlField" required type="text" placeholder="Enter your tribute site name" aria-describedby="basic-addon3"
                                                    value={tributeUrl} onChange={e => setTributeUrl(e.target.value.replace(/[^a-zA-Z0-9\-\d]/ig, ""))} />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">Please enter your website name with only lowercase letters, hyphens and numbers.</Form.Control.Feedback>
                                                {
                                                    doesSiteNameExist ? <div className="red">Site already exists. Please pick another name.</div> : ""
                                                }
                                                <Form.Text id="tributeUrlHelpText" muted>
                                                    Your friends and family can find your site via this friendly tribute web address. Only lowercase letters, hyphens and numbers are allowed.
                                                </Form.Text>
                                            </InputGroup>                                            
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="submit" className="tributeButton">Save Tribute Website</Button>
                        </Col>
                    </Row>
                </Form>
            );
        }
    }
    
    return (
        <Container>
            { renderContent() }
        </Container>
    );
}