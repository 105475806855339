import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import 'firebase/firestore';
import { firestoreDb, cloudStorage } from '../firebase/firebaseConfiguration';
import { Template1 } from "../templates/Template1";

export const UserSite = (props) => {
    const { siteName } = useParams();
    let history = useHistory();
    const [tributeSiteSetting, setTributeSiteSettings] = useState(null);
    const [tributeSitePages, setTributeSitePages] = useState(null);
    const [tributeSitePhotos, setTributeSitePhotos] = useState(null);

    useEffect(() => {
        // Get the user who this site belongs to
        let siteRef = firestoreDb.collection(`sites`).doc(siteName);
        siteRef.get().then((site) => {
            if (site.exists) {
                let siteData = site.data();
                // Get all of this site's settings
                let tributeSiteSettings = firestoreDb.collection(`users/${siteData.userId}/tributesites`).doc(siteData.siteId);
                tributeSiteSettings.get().then((settings) => {
                    if (settings.exists) {
                        let sdata = settings.data();
                        setTributeSiteSettings(sdata);
                        // Get all of this site's pages
                        let tributePages = firestoreDb.collection(`users/${siteData.userId}/tributesites/${siteData.siteId}/pages`);
                        let tpages2 = [];
                        tributePages.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                let d = doc.data();
                                d.id = doc.id;
                                tpages2.push(d);
                            });

                            setTributeSitePages(tpages2);

                            // Get all of this site's tribute photos
                            let tphotos = [];
                            let tributePhotos = firestoreDb.collection(`users/${siteData.userId}/tributesites/${siteData.siteId}/photos`);
                            tributePhotos.get().then((querySnapshot2) => {
                                console.log("# of Photos", querySnapshot2.size);
                                let counter = 0;
                                querySnapshot2.forEach((doc2) => {
                                    counter++;
                                    let d = doc2.data();
                                    d.id = doc2.id;

                                    // Have to do this to get the correct url for this file
                                    const currentFileReference = cloudStorage.ref(`${d.path}`);
                                    currentFileReference.getDownloadURL().then((url) => {
                                        d.url = url;
                                        tphotos.push(d);
                                        if (counter === querySnapshot2.size) {
                                            setTributeSitePhotos(tphotos);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log("Can't get file's url: ", error);
                                    });
                                });
                            }).catch((error) => {
                                // Go to 404 page
                                console.log("Error 6");
                                history.push("/nositefound");
                            });
                        }).catch((error) => {
                            // Go to 404 page
                            console.log("Error 1");
                            history.push("/nositefound");
                        });
                    } else {
                        // Go to 404 page
                        console.log("Error 2");
                        history.push("/nositefound");
                    }
                }).catch((error) => {
                    // Go to 404 page
                    console.log("Error 3");
                    history.push("/nositefound");
                });
            } else {
                // Go to 404 page
                console.log("Error 4");
                history.push("/nositefound");
            }
        }).catch((error) => {
            // Go to 404 page
            console.log("Error 5");
            history.push("/nositefound");
        });
    }, [siteName, history]);

    const renderContent = () => {
        if (tributeSiteSetting !== null && tributeSitePages !== null) {
            return (<Template1 settings={tributeSiteSetting} pages={tributeSitePages} photos={tributeSitePhotos} siteName={siteName} />);
        }
    }

    return (
        <div>
            { renderContent() }
        </div>
    );
}