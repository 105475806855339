import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const ConfirmModal = (props) => {
    const {showModal, fullScreen, title, body, confirmText, confirmMethod, cancelText, cancelMethod} = props;

    return (
        <Modal animation={false} show={showModal} onHide={cancelMethod} fullscreen={fullScreen} backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button className="tributeButton" onClick={confirmMethod}>
                    {confirmText}
                </Button>
                <Button className="tributeButton" onClick={cancelMethod}>
                    {cancelText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}