import React, {useState, useEffect} from 'react';
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

export const ColorPicker = (props) => {
    const {selectedColor} = props;
    const [colorPicked, setColorPicked] = useState(selectedColor);
    const [displayPicker, setDisplayPicker] = useState(false);

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${ colorPicked?.r }, ${ colorPicked?.g }, ${ colorPicked?.b }, ${ colorPicked?.a })`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    useEffect(() => {
        if (!selectedColor) {
            let c = {
                r: '0',
                g: '0',
                b: '0',
                a: '1',
            }
            setColorPicked(c);
        }
    }, [selectedColor]);

    const handleClick = () => {
        setDisplayPicker(!displayPicker);
    };
    
    const handleClose = () => {
        setDisplayPicker(false);
    };

    const handleChange = (c) => {
        setColorPicked(c.rgb);
        if (props.onChange) {
            props.onChange(c.rgb);
        }
    };

    const fetchContent = () => {
        if (colorPicked) {
            return (
                <div>
                    <div style={ styles.swatch } onClick={ e => handleClick() }>
                    <div style={ styles.color } />
                    </div>
                    { displayPicker ? 
                        <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ e => handleClose() }/>
                            <SketchPicker color={ colorPicked } onChange={ e => handleChange(e) } />
                        </div> 
                    : 
                        null 
                    }
                </div>
            );
        } else {
            return (
                <div>None</div>
            )
        }
    }

    return (
        <div>{ fetchContent() }</div>
    )
}