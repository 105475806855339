import React from 'react';
import { Nav, Navbar, FormControl, Button, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import Logo from '../assets/tribute-memorials-logo.jpg';
import { Search } from 'react-bootstrap-icons';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory, withRouter } from "react-router-dom";


const Styles = styled.div`
    .navbar {
        background-color: rgb(14, 91, 99);
        color: #FFF;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .navbar-toggler-icon {
        filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(147deg) brightness(106%) contrast(101%);
    }

    .navbar-toggler {
        color: rgba(255, 255, 255, .5);
    }

    .navbar-brand img {
        max-height: 60px;
    }

    .navbar-brand, .navbar-nav .nav-link {
        color: #FFF;
    }

    .nav-link {
        color: #FFF
    }

    .nav-link:hover {
        color: #FFF !important;
        text-decoration: underline;
    }

    .nav-link:active {
        color: #FFF !important;
        text-decoration: underline;
    }

    .nav-link:visited {
        color: #FFF !important;
        text-decoration: underline;
    }

    .nav-link:focus {
        color: #FFF !important;
        text-decoration: underline;
    }

    .nav-link:focus-within {
        color: #FFF !important;
        text-decoration: underline;
    }

    .dropdown-item:active {
        background-color: #E8ECEE;
    }

    .inline {
        display: inline;
    }

    #searchBar {
        display: inline;
        width: 82%;
        border-radius: 5px 0 0 5px;
    }

    .searchButton {
        display: inline;
        padding: 5px 10px 6px 10px;
        border-radius: 5px;
        color: white;
        background-color: rgb(14, 91, 99);
        border-color: white;
        border-width: 1.5px;
    }

    .searchButton:hover {
        color: yellow;
    }

    .searchButtonOpen {
        display: inline;
        padding: 5px 10px 6px 10px;
        border-radius: 0 5px 5px 0;
        margin-top: -3px;
        margin-left: -2px;
        color: white;
        background-color: rgb(14, 91, 99);
        border-color: white;
        border-width: 1.5px;
    }

    .searchButtonOpen:hover {
        color: yellow;
    }

    .dropdown-menu {
        right: 0;
        left: auto;
    }
`;

export const MainNav = (props) => {
    let history = useHistory();

    const signOut = () => {
        firebase.auth().signOut().then((result) => {
                goTo("/");
        }).catch((error) => console.log("There was an error when signing out: ", error));
    }

    const goTo = (url) => {
        history.push(url);
    }

    const SearchBar = () => (
        <FormControl 
            id="searchBar" 
            type="text" 
            placeholder="Search by first or last name" 
            onKeyPress={event => {
                if (event.key === "Enter") {
                    if (document.getElementById('searchBar') && document.getElementById('searchBar').value !== "") {
                        goTo("/search/" + document.getElementById('searchBar').value);
                    }
                }
            }}
        />
    )

    const SearchButton = () => {
        const [showSearchBar, setShowSearchBar] = React.useState(false)
        const onClick = () => {
            if (document.getElementById('searchBar') && document.getElementById('searchBar').value !== "") {
                goTo("/search/" + document.getElementById('searchBar').value);
            }
    
            if (showSearchBar) {
                setShowSearchBar(false);
            } else {
                setShowSearchBar(true);
            }
        }
        return (
            <div>
                { showSearchBar ? 
                    <div>
                        <SearchBar /> 
                        <Button className="searchButtonOpen" onClick={onClick}><Search /></Button>
                    </div>
                : 
                    <div><Button className="searchButton" onClick={onClick}><Search /></Button></div>
                }
            </div>
        )
    }

    const getMyAccountText = (props) => {
        if (!props.loggedInUser) {
            return "My Account";
        } else {
            if (props.loggedInUser.displayName !== null) {
                return "Hi " + props.loggedInUser.displayName + "!";
            } else {
                if (props.displayName != null) {
                    return "Hi " + props.displayName + "!";
                } else {
                    return "My Account";
                }
            }
        }
    }

    return (
        <Styles>
            <Navbar expand="lg">
                <Navbar.Brand onClick={() => goTo("/")}><img src={Logo} alt="Tribute Memorials Logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="ml-auto">
                        { props.loggedInState ?
                            <NavDropdown className="dropdown-menu-end" id="navbarScrollingDropdown" title={getMyAccountText(props)}>
                                <NavDropdown.Item  onClick={() => goTo("/secure/profile")}>My Profile</NavDropdown.Item>
                                <NavDropdown.Item  onClick={() => goTo("/secure/list-tributes")}>My Tributes</NavDropdown.Item>
                                <NavDropdown.Item onClick={e => signOut()}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        : 
                            <Nav.Item><Nav.Link onClick={() => goTo("/secure/signin")}>Sign In</Nav.Link></Nav.Item>
                        }
                    </Nav>
                </Navbar.Collapse>
                <SearchButton />
            </Navbar>
        </Styles>
    )
}

export default withRouter(MainNav);