import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const AlertModal = (props) => {
    const {showModal, title, body, buttonText, okMethod, fullScreen} = props;

    return (
        <Modal animation={false} show={showModal} fullscreen={fullScreen} onHide={okMethod}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button className="tributeButton" onClick={okMethod}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}