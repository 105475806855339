import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory, withRouter } from "react-router-dom";

const Styles = styled.div`
    .socialButton {
        border: solid transparent 2px;
        border-radius: 60px;
        cursor: pointer;
        padding: 20px;
        margin: 10px 0;
        background-color: #eae8e8;
        font-size: 14px;
        color: white;
        width: 100%;
    }

    .socialButton:hover {
        border-color: rgb(14, 91, 99);
    }

    .socialButton.google {
        background-color: rgb(219, 68, 55);
    }

    .socialButton.facebook {
        background-color: rgb(59, 89, 152);
    }

    .socialButton.twitter {
        background-color: rgb(29, 161, 242);
    }

    #authError
    {
        color: red;
    }

    #socialButtons {
        margin-bottom: 40px;
    }
`;

export const SignIn = (props) => {
    const [authError, setAuthError] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isSignIn, setIsSignIn] = useState(true);
    const history = useHistory();


    firebase.auth().onAuthStateChanged((user) => {
        if (user && !user.isAnonymous) {
            if (!user.emailVerified) {
                history.push("/secure/verify-email");
            } else {
                history.push("/secure/list-tributes");
            }
        }
    });

    const googleSignin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)
            .then((result) => {
                handleLogin(result);
            })
            .catch((error) => {
                handleOauthError(error);
            });
    }
    
    const facebookSignin = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider)
            .then((result) => {
                handleLogin(result);
            })
            .catch((error) => {
                handleOauthError(error);
            });
    }

    const handleOauthError = (error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
            setAuthError("An account already exists for this email address using a different sign in method. Please try a different sign in method.");
        } else if (error.code === "auth/invalid-email") {
            setAuthError("You must supply a valid email address.");
        } else if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
            setAuthError("Invalid email address and/or password");
        } else if (error.code !== "auth/popup-closed-by-user") {
            setAuthError(error.message);
        }
    }

    const handleLogin = (result) => {
        console.log('successfully logged in ', result.user.displayName);
        props.setCurrentUser(result.user);
        props.setDisplayName(result.user.displayName);

        if (!result.user.emailVerified) {
            // Send email verification email
            firebase.auth().currentUser.sendEmailVerification().then(() => {
                history.push("/secure/verify-email");
            });
        } else {
            history.push("/secure/edit-tributes");
        }
    }

    const handleFormSubmit = () => {
        setAuthError("");
        if (email === "") {
            setAuthError("Email address is required");
        } else if (password === "") {
            setAuthError("Password is required");
        } else {
            if (isSignIn) {
                signIn(email, password);
            } else {
                if (name === "") {
                    setAuthError("Name is required");
                } else if (password.length < 8) {
                    setAuthError("Password must be at least 8 characters");
                } else if (passwordConfirm === "") {
                    setAuthError("Confirm Password is required");
                } else if (passwordConfirm.length < 8) {
                    setAuthError("Confirm Password must be at least 8 characters");
                } else if (password !== passwordConfirm) {
                    setAuthError("Password and Cofirm Password must match");
                } else {
                    signUp(name, email, password);
                }
            }
        }
    }

    const signIn = (email, password) => {
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((result) => {
            handleLogin(result);
        })
        .catch((error) => {
            handleOauthError(error);
        });;
    }

    const signUp = (name, email, password) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((result) => {
            let user = result.user;
            user.updateProfile({
                displayName: name
            }).then(() => {
                props.setDisplayName(name);
                handleLogin(result);
            }).catch((error) => {
                setAuthError(error.message);
            });  
        })
        .catch((error) => {
            handleOauthError(error);
        });
    }

    const resetPassword = () => {
        setAuthError("");
        if (email === "") {
            setAuthError("Please enter your email address and then click the \"Forgot Password?\" link again.");
        } else {
            firebase.auth().sendPasswordResetEmail(email).then(() => {
                setAuthError("A reset password email was sent to your email address at " + email + ". Please click on the link in the email we just sent to reset your password.");
            }).catch((error) => {
                setAuthError("We're sorry, but there was an error with the email address you entered. Please enter a valid email address and then clck the Forgot Password link below again.");
            });
        }
    }

    return (
        <Styles>
            <Container>
                <Row id="socialButtons">
                    <Col>
                        <h1>{ isSignIn ? "Sign In" : "Sign Up" }</h1>
                        <div id="authError">{ authError }</div>
                        <Row>
                            <Col>
                                <button id="signin-google" className="socialButton google" onClick={googleSignin}>Sign in with Google</button>
                            </Col>
                            <Col>
                                <button id="signin-facebook" className="socialButton facebook" onClick={facebookSignin}>Sign in with Facebook</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                { isSignIn ? "" :
                    <Row>
                        <Col>
                            <Form.Group controlId="usersName" className="mb-3">
                                <Form.Control type="text" placeholder="Enter Your Name" onChange={e => setName(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <Form.Group controlId="signEmail" className="mb-3">
                            <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="signPassword" className="mb-3">
                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                { isSignIn ? "" :
                    <Row>
                        <Col>
                            <Form.Group controlId="signPasswordConfirm" className="mb-3">
                                <Form.Control type="password" placeholder="Confirm Password" onChange={e => setPasswordConfirm(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <p><Button className="tributeButton" onClick={e => { handleFormSubmit() }}>Sign { isSignIn ? "In" : "Up" } with Email</Button>{isSignIn ? <Button variant="link" className="noButton leftPad20" onClick={resetPassword}>Forgot Password?</Button> : ""}</p>
                <p>{isSignIn ? <Button variant="link" onClick={e => { setIsSignIn(false) }}>Create a New Account</Button> : <Button variant="link" onClick={e => { setIsSignIn(true) }}>Sign In with Email</Button>}</p>
            </Container>
        </Styles>
    )
}

export default withRouter(SignIn);