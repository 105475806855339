import React, { useEffect, useState } from "react";
import { Image, Navbar, Container, Nav, Button, Form, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import styled from 'styled-components';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Parallax } from 'react-parallax';
import { ConfirmModal } from "../components/ConfirmModal";
import 'firebase/firestore';
import { firestoreDb } from '../firebase/firebaseConfiguration';
import { EmailShareButton, EmailIcon, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import { getTimestampString } from '../components/TimeStamp';

const ImageHeader = styled.div`
    position: relative;
    background-size: cover;
    background-image: url(${props => props.headerBackgroundImage});
    background-position: center center;
    background-attachment: fixed;
    color: ${props => props.headerTextColor};
    text-align: center;
    padding: 2.5em 0 2em 0;
    cursor: default;
    vertical-align: middle;
`;

const LogoText = styled.h1`
    color: ${props => props.headerHeadingColor};
    font-size: 4em;
    line-height: 1em;
`;

const HeaderButton = styled.a`
    position: relative;
    display: inline-block;
    text-align: center;
    border-radius: 0.5em;
    text-decoration: none;
    padding: 0.65em 3em 0.65em 3em;
    border: 0;
    cursor: pointer;
    outline: 0;
    color: ${props => props.headerButtonTextColor};
    background: ${props => props.headerButtonColor};
`;

const Styles = styled.div`
    .homepage #header:after {
        content: '';
        background: #1C0920;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        -moz-transition: opacity 2.5s ease-in-out;
        -webkit-transition: opacity 2.5s ease-in-out;
        -ms-transition: opacity 2.5s ease-in-out;
        transition: opacity 2.5s ease-in-out;
        -moz-transition-delay: 0.5s;
        -webkit-transition-delay: 0.5s;
        -ms-transition-delay: 0.5s;
        transition-delay: 0.5s;
    }

    .homepage #header
    {
        height: 100vh;
        min-height: 40em;
    }

        .homepage #header .overlay
        {
            content: '';
            background: #000000;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1.0;
            -moz-transition: opacity 2s ease-in-out;
            -webkit-transition: opacity 2s ease-in-out;
            -o-transition: opacity 2s ease-in-out;
            -ms-transition: opacity 2s ease-in-out;
            transition: opacity 2s ease-in-out;
        }
    
        .homepage #header.ready .overlay
        {
            opacity: 0;
        }

        #header .inner
        {
            position: absolute;
            z-index: 1;
            margin: 0;
            display: inline-block;
            top: 50%; left: 50%;
            transform: translate(-50%,-50%);
        }
        
            #header h1 a
            {
                color: inherit;
            }

        #header .byline
        {
            font-size: 1.25em;
            margin: 0;
            color: ${props => props.sloganTextColor} !important;
        }
        
        #header .button
        {
            border-radius: 100%;
            width: 6em;
            height: 6em;
            line-height: 6em;
            text-align: center;
            font-size: 1.2em;
            padding: 0;
        }
        
        #header header
        {
            display: inline-block;
        }
        
        #header footer
        {
            margin: 1em 0 0 0;
        }
        
        #header hr
        {
            top: 1.5em;
            margin-bottom: 3em;
            border-bottom-color:  ${props => props.headerLinesColor};
            box-shadow: inset 0 1px 0 0  ${props => props.headerLinesColor};
        }

            #header hr:before,
            #header hr:after
            {
                background:  ${props => props.headerLinesColor};
            }

    hr
    {
        position: relative;
        display: block;
        border: 0;
        top: 2em;
        margin-bottom: 4em;
        height: 6px;
        border-top: solid 1px ${props => props.headerLinesColor};
        border-bottom: solid 1px ${props => props.headerLinesColor};
        color: ${props => props.headerLinesColor};
        opacity: 1;
    }

        hr:before,
        hr:after
        {
            content: '';
            position: absolute;
            top: -8px;
            display: block;
            width: 1px;
            height: 21px;
            background: ${props => props.headerLinesColor};
        }

        hr:before
        {
            left: -1px;
        }

        hr:after
        {
            right: -1px;
        }


    .navbar
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 1.5em 0 1.5em 0;
        z-index: 1;
        overflow: hidden;
    }

    .navbar-collapse.collapse.show {
        background: #2e829f;
        border-radius: 5px;
    }

    .navbar-light {
        background: rgba(0,0,0,0);
    }

    .navbar-light .navbar-nav .nav-link {
        color: ${props => props.mainMenuTextColor};
    }
    
    /* Only for Desktop */
    @media (min-width: 992px) {
        .navbar-nav
        {
            position: relative;
            height: 21px;
            width: 100%;
            color: ${props => props.mainMenuTextColor};
            margin-top: 10px;
            border-left: solid 1px ${props => props.headerLinesColor};
            border-right: solid 1px ${props => props.headerLinesColor};
            text-align: center;
            align-items: center;
        }
        
            .navbar-nav:before,
            .navbar-nav:after
            {
                content: '';
                display: block;
                width: 300%;
                position: absolute;
                top: 50%;
                margin-top: -2px;
                height: 5px;
                border-top: solid 1px ${props => props.headerLinesColor};
                border-bottom: solid 1px ${props => props.headerLinesColor};
            }
        
            .navbar-nav:before
            {
                left: 100%;
                margin-left: 1px;
            }
        
            .navbar-nav:after
            {
                right: 100%;
                margin-right: 1px;
            }
        
            .navbar-nav > a
            {
                display: inline-block;
                width: calc(100% / 6);
                border-radius: 0.5em;
                padding: 20px;
                margin: 20px;
                border: solid 1px transparent;
                -moz-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
                -webkit-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
                -o-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
                -ms-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
                transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
                background-color: ${props => props.mainMenuBackgroundColor};
            }
            
            
                .navbar-nav > a,
                .navbar-nav > a > span
                {
                    display: block;
                    color: inherit;
                    text-decoration: none;
                }
                
                .navbar-nav > a:hover
                {
                    border-radius: 0.5em;
                    -moz-transition: color 0.35s ease-in-out;
                    -webkit-transition: color 0.35s ease-in-out;
                    -o-transition: color 0.35s ease-in-out;
                    -ms-transition: color 0.35s ease-in-out;
                    transition: color 0.35s ease-in-out;
                    border: 1px solid rgba(192,192,192,0.5);
                    color: ${props => props.mainMenuTextColorHover} !important;
                    background-color: ${props => props.mainMenuBackgroundColorHover};
                }
    }

    .button:hover
    {
        color: inherit;
        filter:alpha(opacity=80); /* IE */
        -moz-opacity:0.8; /* Mozilla */
        opacity: 0.8; /* CSS3 */
    }

    .wrapper
    {
        background: #fff;
        margin: 0 0 1em 0;
        padding: 2em 0 5em 0;
    }
    
    h2 {
        padding-top: 40px;
        text-align: center;
        font-size: 48px;
        padding-bottom: 20px;
    }

    .carousel-item-padding-40-px {
        padding: 40px;
    }

    /*Blockquote*/
    
    
    /* Let's set and reset some common styles */
    .mb-wrap {
        width: 150px;
        margin: 20px auto;
        padding: 10px;
        position: relative;	
    }
    
    .mb-wrap p{
        margin: 0;
        padding: 0;
    }
    
    .mb-wrap blockquote {
        margin: 0;
        padding: 0;
        position: relative;
    }
    
    .mb-wrap cite {
        font-style: normal;
    }

    .mb-style-3 {
        width: 270px;
    }
    
    .mb-style-3 blockquote{
        background: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 
            inset 0 2px 0 rgba(73, 134, 138, 0.7), 
            -5px -4px 25px rgba(0, 0, 0, 0.3);
    }
    
    .mb-style-3 blockquote:after, 
    .mb-style-3 blockquote:before  {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    .mb-style-3 blockquote:after  {
        border-top-color: rgba(0, 0, 0, 0.1);
        border-width: 10px;
        left: 65%;
        margin-left: -10px;
    }
    
    .mb-style-3 blockquote:before {
        border-top-color: rgba(0,0,0,0.01);
        border-width: 11px;
        left: 65%;
        margin-left: -11px;
    }
    
    .mb-style-3 blockquote p {
        font-family: serif;
        font-size: 18px;
        color: #b4b4b4;
        font-weight: 400;
        line-height: 40px;
        font-style: italic;
        text-indent: 55px;
        position: relative;
    }
    
    .mb-style-3 blockquote p:before{
        content: '\\201C';
        font-family: serif;
        font-style: normal;
        font-weight: 700;
        position: absolute;
        font-size: 130px;
        top: -20px;
        left: -65px;
        color: rgba(73, 134, 138, 1);
        text-shadow: 7px 14px 10px rgba(0, 0, 0, 0.1);
    }
    
    .mb-style-3 .mb-attribution {
        text-align: right;
        padding: 10px 50px 10px 10px;
        position: relative;
    }
    
    .mb-style-3 .mb-author{
        font-family: serif;
        font-weight: 700;
        font-size: 18px;
        color: rgba(73, 134, 138, 1);
        text-shadow: 0 1px 1px rgba(255,255,255,0.7);
    }

    .style2 {
        border-top: 10px solid rgb(240, 244, 244);
    }

    .carousel-container {
        margin-bottom: 80px;
        padding-bottom: 20px;
    }

    .react-multi-carousel-item div {
        white-space: nowrap;
        height: 300px;
    }

    .photoAlbumImage {
        vertical-align: middle;
        max-height: 300px;
        cursor: pointer;
    }

    .helper {
        display: inline-block;
        height: 100%;
        width: 10px;
        vertical-align: middle;
    }

    .react-multiple-carousel__arrow {
        background: rgba(73, 134, 138, 0.7);
    }
        .react-multiple-carousel__arrow:hover {
            background: rgba(73, 134, 138, 1);
        }

    .paddingTop {
        padding-top: 80px;
    }

    .paddingBottom {
        padding-bottom: 80px;
    }

    #footer {
        position: relative;
        overflow: hidden;
        padding: 0px;
        background: rgb(43, 37, 44);
        color: rgb(255, 255, 255);
    }

    .copyright {
        padding-top: 60px;
        padding-bottom: 40px;
        color: white;
    }

    #footer a {
        color: white;
    }

    #footer a:hover {
        text-decoration: none;
    }

    #footer h3 {
        margin-top: 20px;
        margin-bottom: 60px;
    }
`;

export const Template1 = (props) => {
    const {settings, pages, photos, siteName} = props;
    const [guestBookEntries, setGuestBookEntries] = useState(null);
    const [photoComments, setPhotoComments] = useState(null);
    const [showSignGuestBookModal, setShowSignGuestBookMdal] = useState(false);
    const [guestbookYourName, setGuestbookYourName] = useState("");
    const [guestbookYourComment, setGuestbookYourComment] = useState("");
    const [guestBookErrors, setGuestBookErrors] = useState("");

    const [enlargedPhoto, setEnlargedPhoto] = useState(null);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [photoCommentName, setPhotoCommentName] = useState("");
    const [photoCommentText, setPhotoCommentText] = useState("");
    const [photoCommentErrors, setPhotoCommentErrors] = useState("");
    const [enlargedPhotosComments, setEnlargedPhotosComments] = useState(null);
    
    // User Settings
    const [settingHeaderBgImage, setSettingHeaderBgImage] = useState(null);
    const [settingHeaderTextColor, setSettingHeaderTextColor] = useState("#fff");
    const [settingHeaderHeadingColor, setSettingHeaderHeadingColor] = useState("#fff");
    const [settingHeaderButtonColor, setSettingHeaderButtonColor] = useState("#fff");
    const [settingHeaderButtonTextColor, setSettingHeaderButtonTextColor] = useState("#fff");
    const [settingHeaderLinesColor, setSettingHeaderLinesColor] = useState("rgba(192,192,192,.35)");

    const [settingMainMenuTextColor, setSettingMainMenuTextColor] = useState("#fff");
    const [settingMainMenuBackgroundColor, setSettingMainMenuBackgroundColor] = useState("rgba(0,0,0,0)");
    const [settingMainMenuTextColorHover, setSettingMainMenuTextColorHover] = useState("#fff");
    const [settingMainMenuBackgroundColorHover, setSettingMainMenuBackgroundColorHover] = useState("rgba(46, 130, 159, 0.3)");

    const [settingSloganText, setSettingSloganText] = useState("Slogan");
    const [settingSloganTextColor, setSettingSloganTextColor] = useState("rgba(255, 255, 255, 1)");

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    useEffect(() => {
        firestoreDb.collection(`sites/${siteName}/guestBookEntries`).get().then((querySnapshot) => {
            let gbEntries = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                gbEntries.push(data);
            });

            gbEntries = gbEntries.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
            setGuestBookEntries(gbEntries);
        });

        firestoreDb.collection(`sites/${siteName}/photoComments`).get().then((querySnapshot) => {
            let pComments = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                pComments.push(data);
            });

            pComments = pComments.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
            setPhotoComments(pComments);
        });

        if (settings && settings.templateSettings) {
            if (settings.templateSettings.headerTextColor) {
                setSettingHeaderTextColor("rgba(" + settings.templateSettings.headerTextColor.r + ", " + settings.templateSettings.headerTextColor.g + ", " + settings.templateSettings.headerTextColor.b + ", " + settings.templateSettings.headerTextColor.a + ")");
            }
            if (settings.templateSettings.headerHeadingColor) {
                setSettingHeaderHeadingColor("rgba(" + settings.templateSettings.headerHeadingColor.r + ", " + settings.templateSettings.headerHeadingColor.g + ", " + settings.templateSettings.headerHeadingColor.b + ", " + settings.templateSettings.headerHeadingColor.a + ")");
            }
            if (settings.templateSettings.headerButtonColor) {
                setSettingHeaderButtonColor("rgba(" + settings.templateSettings.headerButtonColor.r + ", " + settings.templateSettings.headerButtonColor.g + ", " + settings.templateSettings.headerButtonColor.b + ", " + settings.templateSettings.headerButtonColor.a + ")");
            }
            if (settings.templateSettings.headerButtonTextColor) {
                setSettingHeaderButtonTextColor("rgba(" + settings.templateSettings.headerButtonTextColor.r + ", " + settings.templateSettings.headerButtonTextColor.g + ", " + settings.templateSettings.headerButtonTextColor.b + ", " + settings.templateSettings.headerButtonTextColor.a + ")");
            }
            if (settings.templateSettings.headerLinesColor) {
                setSettingHeaderLinesColor("rgba(" + settings.templateSettings.headerLinesColor.r + ", " + settings.templateSettings.headerLinesColor.g + ", " + settings.templateSettings.headerLinesColor.b + ", " + settings.templateSettings.headerLinesColor.a + ")");
            }
            if (settings.templateSettings.mainMenuTextColor) {
                setSettingMainMenuTextColor("rgba(" + settings.templateSettings.mainMenuTextColor.r + ", " + settings.templateSettings.mainMenuTextColor.g + ", " + settings.templateSettings.mainMenuTextColor.b + ", " + settings.templateSettings.mainMenuTextColor.a + ")");
            }
            if (settings.templateSettings.mainMenuBackgroundColor) {
                setSettingMainMenuBackgroundColor("rgba(" + settings.templateSettings.mainMenuBackgroundColor.r + ", " + settings.templateSettings.mainMenuBackgroundColor.g + ", " + settings.templateSettings.mainMenuBackgroundColor.b + ", " + settings.templateSettings.mainMenuBackgroundColor.a + ")");
            }
            if (settings.templateSettings.mainMenuTextColorHover) {
                setSettingMainMenuTextColorHover("rgba(" + settings.templateSettings.mainMenuTextColorHover.r + ", " + settings.templateSettings.mainMenuTextColorHover.g + ", " + settings.templateSettings.mainMenuTextColorHover.b + ", " + settings.templateSettings.mainMenuTextColorHover.a + ")");
            }
            if (settings.templateSettings.mainMenuBackgroundColorHover) {
                setSettingMainMenuBackgroundColorHover("rgba(" + settings.templateSettings.mainMenuBackgroundColorHover.r + ", " + settings.templateSettings.mainMenuBackgroundColorHover.g + ", " + settings.templateSettings.mainMenuBackgroundColorHover.b + ", " + settings.templateSettings.mainMenuBackgroundColorHover.a + ")");
            }
            if (settings.templateSettings.sloganText) {
                setSettingSloganText(settings.templateSettings.sloganText);
            }
            if (settings.templateSettings.sloganTextColor) {
                setSettingSloganTextColor("rgba(" + settings.templateSettings.sloganTextColor.r + ", " + settings.templateSettings.sloganTextColor.g + ", " + settings.templateSettings.sloganTextColor.b + ", " + settings.templateSettings.sloganTextColor.a + ")");
            }
            if (settings.templateSettings.headerMainImage) {
                setSettingHeaderBgImage(settings.templateSettings.headerMainImage);
            }
        }
    }, [settings, pages, photos, siteName]);

    const signGuestBook = () => {
        let errors = "";
        if (guestbookYourName === "") {
            errors += "Your name is required.<br />";
        }
        if (guestbookYourComment === "") {
            errors += "Your comment is required.<br />";
        }

        if (errors !== "") {
            setGuestBookErrors("<br />" + errors);
        } else {
            setGuestBookErrors("");
            let guestBookEntry = {
                "name": guestbookYourName,
                "comment": guestbookYourComment,
                "date": getTimestampString()[0],
                "orderByDate": getTimestampString()[1]
            }

            let guestBookCollection = firestoreDb.collection(`sites/${siteName}/guestBookEntries`);
            guestBookCollection.add(guestBookEntry).then((docRef) => {
                firestoreDb.collection(`sites/${siteName}/guestBookEntries`).get().then((querySnapshot) => {
                    let gbEntries = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        gbEntries.push(data);
                    });
        
                    gbEntries = gbEntries.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
                    setGuestBookEntries(gbEntries);

                    // Reset the form and close the modal
                    setGuestbookYourName("");
                    setGuestbookYourComment("");
                    setShowSignGuestBookMdal(false);
                }).catch((error) => {
                    setGuestBookErrors("There was an error retrieving guest book entries for this site. Please refresh the page.<br />");
                });
            }).catch((error) => {
                setGuestBookErrors("There was an error writing your guest book entry. Please try again.<br />");
            });
        }
    }

    const savePhotoComment = () => {
        let errors = "";
        if (photoCommentName === "") {
            errors += "Your name is required.<br />";
        }
        if (photoCommentText === "") {
            errors += "Your comment is required.<br />";
        }

        if (errors !== "") {
            setPhotoCommentErrors("<br />" + errors);
        } else {
            setPhotoCommentErrors("");

            let photoCommentEntry = {
                "name": photoCommentName,
                "comment": photoCommentText,
                "date": getTimestampString()[0],
                "orderByDate": getTimestampString()[1],
                "photoId": enlargedPhoto.id
            }

            let photoCommentCollection = firestoreDb.collection(`sites/${siteName}/photoComments`);
            photoCommentCollection.add(photoCommentEntry).then((docRef) => {
                firestoreDb.collection(`sites/${siteName}/photoComments`).get().then((querySnapshot) => {
                    let photoCommentEntries = [];
                    let enlargedphotoCommentEntries = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        photoCommentEntries.push(data);
                        if (data.photoId === enlargedPhoto.id) {
                            enlargedphotoCommentEntries.push(data);
                        }
                    });
        
                    photoCommentEntries = photoCommentEntries.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
                    setPhotoComments(photoCommentEntries);

                    enlargedphotoCommentEntries = enlargedphotoCommentEntries.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
                    setEnlargedPhotosComments(enlargedphotoCommentEntries);

                    // Reset the form and close the modal
                    setPhotoCommentName("");
                    setPhotoCommentText("");
                    setPhotoCommentErrors("Your comment has been saved. Create another comment or click the Close button below to close this image.");
                }).catch((error) => {
                    setPhotoCommentErrors("There was an error retrieving photo comments for this site. Please refresh the page.<br />");
                });
            }).catch((error) => {
                setPhotoCommentErrors("There was an error writing your comment to this photo. Please try again.<br />");
            });
        }
    }

    const enlargeImage = (e) => {
        let photoId = e.className.split(" ")[1];
        let photo = {
            "id": photoId,
            "url": e.src
        }
        setEnlargedPhoto(photo);
        setShowPhotoModal(true);
        getEnlargedPhotoComments(photo);
    }

    const closePhotoComment = () => {
        setPhotoCommentName("");
        setPhotoCommentText("");
        setPhotoCommentErrors("");
        setEnlargedPhoto(null);
        setEnlargedPhotosComments(null);
        setShowPhotoModal(false);
    }

    const getEnlargedPhotoComments = (photo) => {
        let thisPhotosComments = [];
        if (photoComments && photo) {
            photoComments.forEach(function (pc) {
                if (pc.photoId === photo.id) {
                    thisPhotosComments.push(pc);
                }
            });
        }
        setEnlargedPhotosComments(thisPhotosComments);
    }

    
    return (
        <Styles mainMenuTextColor={settingMainMenuTextColor} mainMenuBackgroundColor={settingMainMenuBackgroundColor} mainMenuTextColorHover={settingMainMenuTextColorHover} 
            mainMenuBackgroundColorHover={settingMainMenuBackgroundColorHover} headerLinesColor={settingHeaderLinesColor} sloganTextColor={settingSloganTextColor}>
            <div className="homepage" id="page-top">
                <Parallax strength={300}>
                    <ImageHeader id="header" headerBackgroundImage={settingHeaderBgImage?.url} headerTextColor={settingHeaderTextColor}>
                        <div className="inner">
                            <header>
                                <LogoText headerHeadingColor={settingHeaderHeadingColor}>{settings.tributeFirstName} {settings.tributeLastName}</LogoText>
                                <hr />
                                <span id="slogan" className="byline">{settingSloganText}</span>
                            </header>
                            <footer>
                                <HeaderButton headerButtonTextColor={settingHeaderButtonTextColor} headerButtonColor={settingHeaderButtonColor} href="#home" className="button circled scrolly">Remember</HeaderButton>
                            </footer>
                        </div>
                        
                        <Navbar collapseOnSelect expand="lg">
                            <Container>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link href="#home">{ pages.find(obj => { return obj.pageId === "home"; }).pageTitle }</Nav.Link>
                                        <Nav.Link href="#photos">{ pages.find(obj => { return obj.pageId === "photoAlbum" }).pageTitle }</Nav.Link>
                                        <Nav.Link href="#guestbook">{ pages.find(obj => { return obj.pageId === "guestBook" }).pageTitle }</Nav.Link>
                                        <Nav.Link href="#page4">{ pages.find(obj => { return obj.pageId === "ep1" }).pageTitle }</Nav.Link>
                                        <Nav.Link href="#page5">{ pages.find(obj => { return obj.pageId === "ep2" }).pageTitle }</Nav.Link>
                                        <Nav.Link href="#share">Share</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </ImageHeader>
                </Parallax>

                <div id="home" className="wrapper">
                    <article id="main" className="container special">
                        <header>
                            <h2>{ pages.find(obj => { return obj.pageId === "home"; }).pageTitle }</h2>
                        </header>
                        <div>
                            { parse(pages.find(obj => { return obj.pageId === "home"; }).pageContent) }
                        </div>
                    </article>
                </div>

                <div id="photos" className="style2 paddingTop">
                    <article id="main" className="container special">
                        <header>
                            <h2>{ pages.find(obj => { return obj.pageId === "photoAlbum"; }).pageTitle }</h2>
                            <p className="center">
                                Click on an image to enlarge it and leave a comment on the photo.
                            </p>
                        </header>
                    </article>
                </div>

                <div className="paddingBottom">
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        ssr={false}
                        infinite={true}
                        autoPlay={false}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                        photos ?
                        photos.map((photo) =>
                            <div key={photo?.id}><span className="helper"></span><Image className={"photoAlbumImage " + photo?.id} src={photo?.url} onClick={e => {enlargeImage(e.target)}} fluid /></div>
                        )
                        : <div></div>
                        }
                    </Carousel>
                </div>

                <div id="guestbook" className="wrapper style2">
                    <article id="main" className="container special">
                        <header>
                            <h2>{ pages.find(obj => { return obj.pageId === "guestBook"; }).pageTitle }</h2>
                        </header>

                        <p className="center">
                            <Button className="noButton" variant="link" onClick={e => { setShowSignGuestBookMdal(true) }}>Sign the Guestbook</Button>
                        </p>
                        <br /><br />
                        
                        <section className="main" id="guestBookEntries">
                            <Row xs={1} sm={1} md={2} lg={3}>
                                {
                                guestBookEntries ?
                                guestBookEntries.map((entry) =>
                                    <Col key={entry.id}>
                                        <div className="mb-wrap mb-style-3">
                                            <blockquote>
                                                <p>{entry.comment}</p>
                                            </blockquote>
                                            <div className="mb-attribution">
                                                <p className="mb-author">{entry.name}</p>
                                                <p className="mb-date">{entry.date}</p>
                                            </div>
                                        </div>
                                    </Col> 
                                )
                                : ""
                                }
                            </Row>
                        </section>

                        <br /><br />
                        <p className="center">
                            <Button className="noButton" variant="link" onClick={e => { setShowSignGuestBookMdal(true) }}>Sign the Guestbook</Button>
                        </p>
                    </article>
                </div>

                <div id="page4" className="wrapper style2">
                    <article id="main" className="container special">
                        <header>
                            <h2>{ pages.find(obj => { return obj.pageId === "ep1"; }).pageTitle }</h2>
                        </header>
                        <div>
                            { parse(pages.find(obj => { return obj.pageId === "ep1"; }).pageContent) }
                        </div>
                    </article>
                </div>
                
                <div id="page5" className="wrapper style2">
                    <article id="main" className="container special">
                        <header>
                            <h2>{ pages.find(obj => { return obj.pageId === "ep2"; }).pageTitle }</h2>
                        </header>
                        <div>
                            { parse(pages.find(obj => { return obj.pageId === "ep2"; }).pageContent) }
                        </div>
                    </article>
                </div>

                <div id="footer">
                    <Container>
                        <hr />
                        <Row>
                            <Col className="center">	
                                <section className="contact" id="share">
                                    <header>
                                        <h3>Share this Tribute Website with Your Family & Friends</h3>
                                    </header>
                                    <Row>
                                        <Col>
                                            <FacebookShareButton
                                                url={"https://tribute.memorials.com/remember/" + siteName}
                                                quote={settings.tributeFirstName + " " + settings.tributeLastName + " Tribute Website"}
                                            >
                                                <FacebookIcon size={64} round />
                                            </FacebookShareButton>
                                        </Col>
                                        <Col>
                                            <TwitterShareButton
                                                url={"https://tribute.memorials.com/remember/" + siteName}
                                                title={settings.tributeFirstName + " " + settings.tributeLastName + " Tribute Website"}
                                            >
                                                <TwitterIcon size={64} round />
                                            </TwitterShareButton>
                                        </Col>
                                        <Col>
                                            <PinterestShareButton
                                                url={"https://tribute.memorials.com/remember/" + siteName}
                                                media={`https://tributememorials.com/userfiles/backgrounds/files/background1386537918.jpg`}
                                            >
                                                <PinterestIcon size={64} round />
                                            </PinterestShareButton>
                                        </Col>
                                        <Col>
                                            <LinkedinShareButton
                                                url={"https://tribute.memorials.com/remember/" + siteName}
                                            >
                                                <LinkedinIcon size={64} round />
                                            </LinkedinShareButton>
                                        </Col>
                                        <Col>
                                            <EmailShareButton
                                                url={"https://tribute.memorials.com/remember/" + siteName}
                                                subject={settings.tributeFirstName + " " + settings.tributeLastName + " Tribute Website"}
                                                body={"I wanted to share this tribute site from TributeMemorials.com with you. You can view it <a href='https://tribute.memorials.com/remember/" + siteName + "'>here</a>."}
                                            >
                                                <EmailIcon size={64} round />
                                            </EmailShareButton>
                                        </Col>
                                    </Row>
                                    
                                    <div className="copyright">&copy; 2021 <a href="https://www.memorials.com" rel="noreferrer" target="_blank">Memorials.com</a>. All Rights Reserved.</div>

                                </section>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <ConfirmModal 
                fullscreen={false}
                showModal={showSignGuestBookModal}
                title={"Sign " + settings.tributeFirstName + "'s Guestbook!"}
                body={
                    <div>
                        <p>
                            Please enter your name and message below to sign {settings.tributeFirstName}'s guestbook.
                            { guestBookErrors !== "" ? <div>{parse(guestBookErrors)}</div> : "" }
                        </p>
                        <Form.Group controlId="guestbookName">
                            <Form.Label>Your Name*</Form.Label>
                            <Form.Control type="text" placeholder="Your Name" onChange={e => setGuestbookYourName(e.target.value.replace(/[^a-zA-Z0-9\-'\s\d]/ig, ""))} />
                        </Form.Group>
                        <Form.Group controlId="guestbooText">
                            <Form.Label>Your Comment*</Form.Label>
                            <Form.Control as="textarea" placeholder="Your Comment" rows={3} onChange={e => setGuestbookYourComment(e.target.value)} />
                        </Form.Group>
                    </div>
                }
                confirmText="Save"
                confirmMethod={signGuestBook}
                cancelText="Cancel"
                cancelMethod={e => { setShowSignGuestBookMdal(false) }}
            />

            <ConfirmModal 
                fullScreen={true}
                showModal={showPhotoModal}
                title={"View Photo & Leave a Comment"}
                body={
                    <Container>
                        <Row>
                            <Col>
                                <Image src={enlargedPhoto?.url} className="bigImage" fluid />
                            </Col>
                            <Col>
                                <p className="red">
                                    { photoCommentErrors !== "" ? <div>{parse(photoCommentErrors)}</div> : "" }
                                </p>
                                <Form.Group controlId="photoCommentName">
                                    <Form.Label>Your Name*</Form.Label>
                                    <Form.Control type="text" placeholder="Your Name" value={photoCommentName} onChange={e => setPhotoCommentName(e.target.value.replace(/[^a-zA-Z0-9\-'\s\d]/ig, ""))} />
                                </Form.Group>
                                <Form.Group controlId="photoCommentText">
                                    <Form.Label>Your Comment*</Form.Label>
                                    <Form.Control as="textarea" placeholder="Your Comment" value={photoCommentText} rows={3} onChange={e => setPhotoCommentText(e.target.value)} />
                                </Form.Group>
                                <input type="hidden" id="photoId" name="photoId" value={enlargedPhoto?.id} />
                                {
                                (enlargedPhotosComments && enlargedPhotosComments.length > 0) ?
                                    <div><br /><br /><h3>Photo Comments</h3></div>
                                :
                                    ""
                                }

                                {
                                (enlargedPhotosComments && enlargedPhotosComments.length > 0) ?
                                enlargedPhotosComments.map((c) =>
                                    <Row>
                                        <Col>
                                            <div className="template1PhotoCommentText">
                                                {c.comment}
                                            </div>
                                            <div className="template1PhotoCommentByline">
                                                - {c.name} - {c.date}
                                            </div>
                                            <br /><br />
                                        </Col>
                                    </Row>
                                )
                                : <div></div>
                                }
                                <br />
                            </Col>
                        </Row>
                    </Container>
                }
                confirmText="Save"
                confirmMethod={savePhotoComment}
                cancelText="Close"
                cancelMethod={closePhotoComment}
            />
        </Styles>
    );
}