import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Tab, Tabs, Form, Table, Image } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firestoreDb, cloudStorage } from '../../firebase/firebaseConfiguration';
import { Editor } from '@tinymce/tinymce-react';
import { AlertModal } from '../../components/AlertModal';
import { ConfirmModal } from '../../components/ConfirmModal';
import { useDropzone } from 'react-dropzone';
import { getTimestampString } from '../../components/TimeStamp';

export const EditPages = (props) => {
    const {currentUser} = props;
    let history = useHistory();
    const { id } = useParams();
    const [pages, setPages] = useState(null);
    const [currentTab, setCurrentTab] = useState("homePage");
    
    const [homePageTitle, setHomePageTitle] = useState("");
    const [homePageContent, setHomePageContent] = useState("");
    const [initialHomePageContent, setInitialHomePageContent] = useState("");

    const [ep1PageTitle, setEp1PageTitle] = useState("");
    const [ep1PageContent, setEp1PageContent] = useState("");
    const [initialEp1PageContent, setInitialEp1PageContent] = useState("");
    
    const [ep2PageTitle, setEp2PageTitle] = useState("");
    const [ep2PageContent, setEp2PageContent] = useState("");
    const [initialEp2PageContent, setInitialEp2PageContent] = useState("");
    
    const [guestBookPageTitle, setGuestBookPageTitle] = useState("");
    const [guestBookEntries, setGuestBookEntries] = useState(null);
    const [showDeleteGuestBookEntryModal, setShowDeleteGuestBookEntryModal] = useState(false);
    const [guestBookEntryToDelete, setGuestBookEntryToDelete] = useState(false);

    const [alertTitle, setAlertTitle] = useState("");
    const [alertBody, setAlertBody] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [fullScreenModal, setFullScreenModal] = useState(true);

    // Photo Album
    const [photoAlbumPageTitle, setPhotoAlbumPageTitle] = useState("");
    const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);
    const [photoToDelete, setPhotoToDelete] = useState(false);
    const [photoAlbumPhotos, setPhotoAlbumPhotos] = useState(null);
    const [photoCommentsPhoto, setPhotoCommentsPhoto] = useState(null);
    const [showPhotoCommentsModal, setShowPhotoCommentsModal] = useState(false);
    const [photoComments, setPhotoComments] = useState(null);
    const onDrop = useCallback((acceptedFiles) => {
        let photoCounter = 0;
        acceptedFiles.forEach((file) => {
            photoCounter++;
            // Write the file to Cloud Storage
            const fileRef = cloudStorage.ref(`userphotos/${currentUser.uid}/${id}/${file.name}`);
            fileRef.put(file).then((snapshot) => {
                // Add the file to the firestore
                let photo = {
                    "path": `userphotos/${currentUser.uid}/${id}/${file.name}`,
                    "date": getTimestampString()[0],
                    "orderByDate": getTimestampString()[1]
                }
                
                const photosCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites/${id}/photos`);
                photosCollection.add(photo).then((docRef) => {
                    // File was added to the datastore
                    // Get photo album photos
                    const uploadedPhotoDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}/photos/${docRef.id}`);
                    uploadedPhotoDocument.get().then((doc) => {
                        if (doc.exists) {
                            let data = doc.data();
                            data.id = doc.id;

                            // Have to do this to get the correct url for this file
                            const currentFileReference = cloudStorage.ref(`${data.path}`);
                            currentFileReference.getDownloadURL().then((url) => {
                                data.url = url;
                                let p = [];
                                if (photoAlbumPhotos != null) {
                                    p = photoAlbumPhotos;
                                }
                                p.unshift(data);
                                setPhotoAlbumPhotos(p);
                                if (photoCounter === acceptedFiles.length) {
                                    setFullScreenModal(false);
                                    let extraS = "";
                                    if (acceptedFiles.length > 1) {
                                        extraS = "s";
                                    }
                                    setAlertTitle("Image" + extraS + " Successfully Uploaded");
                                    setAlertBody("You have successfully uploaded " + acceptedFiles.length + " image" + extraS + " to your tribute site.");
                                    setShowModal(true);
                                }
                            })
                            .catch((error) => {
                                console.log("Can't get file's url: ", error);
                            });
                        }
                    }).catch((error) => {
                        console.log("Can't add the newly uploaded file to the page: ", error);
                    });
                }).catch((error) => {
                    setFullScreenModal(false);
                    console.log(error);
                    setAlertTitle("Error uploading file");
                    setAlertBody("There was an error uploading your file called " + file.name + ". Please try again.");
                    setShowModal(true);
                });
            }).catch((error) => {
                setFullScreenModal(false);
                console.log(error);
                setAlertTitle("Error uploading file");
                setAlertBody("There was an error uploading your file called " + file.name + ". Please try again.");
                setShowModal(true);
            });
        });
    }, [id, currentUser, photoAlbumPhotos]);
    const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/jpeg, image/png'});

    const viewEnlargedImage = (img) => {
        setAlertTitle("Your Image");
        setFullScreenModal(true);
        setAlertBody(<Image src={img.url} className="enlargedImage" fluid />);
        setShowModal(true);
    }


    firebase.auth().onAuthStateChanged((user) => {
        if (!user || (user && user.isAnonymous)) {
            history.push("/secure/signin");
        }
    });

    useEffect(() => {
        if (currentUser) {
            if (!currentUser.emailVerified) {
                history.push("/secure/verify-email");
            } else {
                let tpages = [];
                const pagesCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites/${id}/pages`);
                pagesCollection.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        let d = doc.data();
                        d.id = doc.id;
                        tpages.push(d);

                        if (d.pageId === "home") {
                            setHomePageTitle(d.pageTitle);
                            setHomePageContent(d.pageContent);
                            setInitialHomePageContent(d.pageContent);
                        } else if (d.pageId === "ep1") {
                            setEp1PageTitle(d.pageTitle);
                            setEp1PageContent(d.pageContent);
                            setInitialEp1PageContent(d.pageContent);
                        } else if (d.pageId === "ep2") {
                            setEp2PageTitle(d.pageTitle);
                            setEp2PageContent(d.pageContent);
                            setInitialEp2PageContent(d.pageContent);
                        } else if (d.pageId === "guestBook") {
                            setGuestBookPageTitle(d.pageTitle);
                            const existingTributeSiteDocument = firestoreDb.collection(`users/${currentUser.uid}/tributesites/`).doc(id);
                            existingTributeSiteDocument.get().then((doc) => {
                                if (doc.exists) {
                                    let data = doc.data();

                                    firestoreDb.collection(`sites/${data.tributeUrl}/guestBookEntries`).get().then((geSnapshot) => {
                                        let geArray = [];
                                        geSnapshot.forEach((docGe) => {
                                            let geData = docGe.data();
                                            geData.id = docGe.id;
                                            geArray.push(geData);
                                        });
                                        setGuestBookEntries(geArray);
                                    }).catch((error) => {
                                        console.log("There was an error fetching guest book entries.", error);
                                    });
                                }
                            }).catch((error) => {
                                console.log("Error getting site", error);
                            });
                        } else if (d.pageId === "photoAlbum") {
                            setPhotoAlbumPageTitle(d.pageTitle);
                            const exisitingPhotosCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites/${id}/photos`);
                            exisitingPhotosCollection.get().then((photoSnapshot) => {
                                let tphotos = [];
                                
                                let pCounter = 0;
                                photoSnapshot.forEach((photo) => {
                                    let p = photo.data();
                                    p.id = photo.id;
                                    pCounter++;

                                    // Have to do this to get the correct url for this file
                                    const currentFileReference = cloudStorage.ref(`${p.path}`);
                                    currentFileReference.getDownloadURL().then((url) => {
                                        p.url = url;
                                        tphotos.push(p);
                                    })
                                    .catch((error) => {
                                        console.log("Can't get file's url: ", error);
                                    });

                                    if (pCounter === photoSnapshot.size) {
                                        tphotos = tphotos.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
                                        setPhotoAlbumPhotos(tphotos);
                                    }
                                });
                            });
                        }
                    });

                    setPages(tpages);
                });
            }
        }
        
    }, [currentUser, id, history]);

    const handleHomePageChange = (e) => {
        if (e) {
            setHomePageContent(e);
        }
    }

    const handleEp1PageChange = (e) => {
        if (e) {
            setEp1PageContent(e);
        }
    }

    const handleEp2PageChange = (e) => {
        if (e) {
            setEp2PageContent(e);
        }
    }

    const tabSelect = (key) => {
        setCurrentTab(key);
    }

    const closeModal = () => {
        setFullScreenModal(false);
        setShowModal(false);
    }

    const openDeletePhotoModal = (photo) => {
        setPhotoToDelete(photo);
        setShowDeletePhotoModal(true);
    }

    const confirmDeletePhoto = () => {
        const existingTributeSiteDocument = firestoreDb.collection(`users/${currentUser.uid}/tributesites/`).doc(id);
        existingTributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                // Delete the photo from cloud storage
                // Create a reference to the file to delete
                const fileRef = cloudStorage.ref(photoToDelete.path);
                // Delete the file
                fileRef.delete().then(() => {
                    // Delete the photo for the firestoredb
                    firestoreDb.collection(`users/${currentUser.uid}/tributesites/${id}/photos/`).doc(photoToDelete.id).delete().then(() => {
                        // Get all of the comments with this photo id
                        firestoreDb.collection(`sites/${data.tributeUrl}/photoComments`).where("photoId", "==", photoToDelete.id).get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                firestoreDb.collection(`sites/${data.tributeUrl}/photoComments/`).doc(doc.id).delete().then(() => {
                                }).catch((error) => {
                                    console.log("Error deleting comments for this photo: ", error);
                                });
                            });
                        }).catch((error) => {
                            console.log("There was an error fetching data.");
                        });

                        // Remove the photo from the current photo list
                        let newPhotoList = [];
                        photoAlbumPhotos.forEach(function (p) {
                            if (p.id !== photoToDelete.id) {
                                newPhotoList.push(p);
                            }
                        });
                        setPhotoToDelete(null);
                        setPhotoAlbumPhotos(newPhotoList);
                    }).catch((error) => {
                        console.log("Error deleting photo from db: ", error);
                    });
                }).catch((error) => {
                    console.log("There was an error trying to delete this photo.");
                });

                setShowDeletePhotoModal(false);
            }
        });
    }


    const openDeleteGuestBookEntryModal = (entry) => {
        setGuestBookEntryToDelete(entry);
        setShowDeleteGuestBookEntryModal(true);
    }

    const confirmDeleteGuestBookEntry = () => {
        const existingTributeSiteDocument = firestoreDb.collection(`users/${currentUser.uid}/tributesites/`).doc(id);
        existingTributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                // Delete the guest book entry from firestore
                firestoreDb.collection(`sites/${data.tributeUrl}/guestBookEntries/`).doc(guestBookEntryToDelete.id).delete().then(() => {
                    // Remove the guest book entry from the current list
                    let newGuestBookList = [];
                    guestBookEntries.forEach(function (ge) {
                        if (ge.id !== guestBookEntryToDelete.id) {
                            newGuestBookList.push(ge);
                        }
                    });
                    setGuestBookEntryToDelete(null);
                    setGuestBookEntries(newGuestBookList);
                }).catch((error) => {
                    console.log("Error deleting guest book entry from db: ", error);
                });
            
                setShowDeleteGuestBookEntryModal(false);
            }
        });
    }

    const savePageData = (pageId) => {
        let title = "";
        let content = "";

        if (pageId === "home") {
            title = homePageTitle;
            content = homePageContent;
        } else if (pageId === "ep1") {
            title = ep1PageTitle;
            content = ep1PageContent;
        } else if (pageId === "ep2") {
            title = ep2PageTitle;
            content = ep2PageContent;
        } else if (pageId === "guestBook") {
            title = guestBookPageTitle;
        } else if (pageId === "photoAlbum") {
            title = photoAlbumPageTitle;
        }

        if (title === "") {
            setFullScreenModal(false);
            setAlertTitle("Missing Required Fields");
            setAlertBody("Page Title is a required field. Please try again.");
            setShowModal(true);
        } else {
            // Save the page to firestore
            let pageToSave = {
                "pageId": pageId,
                "pageTitle": title,
                "pageContent": content
            };

            if (!pages || pages === null || pages.length === 0) {
                // Add the page to the pages collection
                addPage(pageToSave, title);
            } else {
                // Update the page in the pages collection
                // See if the page already exists
                let currentPage = null;
                pages.forEach(function (p) {
                    if (p.pageId === pageId) {
                        currentPage = p;
                    }
                });

                if (currentPage === null) {
                    // Add page to existing collection
                    addPage(pageToSave, title);
                } else {
                    // Update existing document
                    const pageDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}/pages/${currentPage.id}`);
                    pageDocument.get().then((doc) => {
                        if (doc.exists) {
                            let data = doc.data();
                            data.pageTitle = title;
                            data.pageContent = content;

                            pageDocument.update(data).then(() => {
                                setFullScreenModal(false);
                                setAlertTitle("Page Successfully Saved");
                                setAlertBody("The page with the title of \"" + title + "\" has been successfully updated.");
                                setShowModal(true);
                            }).catch((error) => { 
                                console.error("Error updating document: ", error);
                                setAlertTitle("Error Updating Page");
                                setAlertBody("There was an error updating the page with the title of \"" + title + "\" to the system. Please close this window and then try again.");
                                setShowModal(true);
                            });
                        }
                    }).catch((error) => {
                        setFullScreenModal(false);
                        console.error("Error updating document: ", error);
                        setAlertTitle("Error Updating Page");
                        setAlertBody("There was an error updating the page with the title of \"" + title + "\" to the system. Please close this window and then try again.");
                        setShowModal(true);
                    });
                }
            }
        }
    }

    const addPage = (pageToSave, title) => {
        const pagesCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites/${id}/pages`);
        pagesCollection.add(pageToSave)
        .then((docRef) => {
            setFullScreenModal(false);
            setAlertTitle("Page Successfully Saved")
            setAlertBody("The page with the title of \"" + title + "\" has been successfully added.");
            setShowModal(true);
        })
        .catch((error) => {
            setFullScreenModal(false);
            setAlertTitle("Error Adding Page");
            setAlertBody("There was an error adding the page with the title of \"" + title + "\" to the system. Please close this window and then try again.");
            setShowModal(true);
        });
    }

    const editComments = (photo) => {
        setPhotoCommentsPhoto(photo);

        const existingTributeSiteDocument = firestoreDb.collection(`users/${currentUser.uid}/tributesites/`).doc(id);
        existingTributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                firestoreDb.collection(`sites/${data.tributeUrl}/photoComments`).where("photoId", "==", photo.id).get().then((querySnapshot) => {
                    let cArray = [];
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        let c = doc.data();
                        c.id = doc.id;
                        cArray.push(c);
                    });
                    cArray = cArray.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
                    setPhotoComments(cArray);
                    setShowPhotoCommentsModal(true);
                }).catch((error) => {
                    setShowPhotoCommentsModal(true);
                    console.log("There was an error fetching data.");
                });
            } else {
                setPhotoComments(null);
                setShowPhotoCommentsModal(true);
            }
        });
    }

    const confirmDeleteComment = (comment, event) => {
        event.target.innerText = "Confirm Delete"
        event.target.onclick = () => deleteComment(comment);
    }

    const deleteComment = (comment) => {
        console.log("Deleting comment: ", comment);
        const existingTributeSiteDocument = firestoreDb.collection(`users/${currentUser.uid}/tributesites/`).doc(id);
        existingTributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                firestoreDb.collection(`sites/${data.tributeUrl}/photoComments/`).doc(comment.id).delete().then(() => {
                    firestoreDb.collection(`sites/${data.tributeUrl}/photoComments`).where("photoId", "==", comment.photoId).get().then((querySnapshot) => {
                        let cArray = [];
                        querySnapshot.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            let c = doc.data();
                            c.id = doc.id;
                            cArray.push(c);
                        });
                        cArray = cArray.sort((a, b) => (a.orderByDate < b.orderByDate) ? 1 : -1);
                        setPhotoComments(cArray);
                    }).catch((error) => {
                        console.log("There was an error fetching data.");
                    });
                }).catch((error) => {
                    console.log("Error removing comment: ", error);
                });
            }
        });
    }

    const renderContent = () => {
        if (currentUser === null) {
            return (
                <Container>Loading data...please wait.</Container>
            );
        } else {
            return (
                <Container>
                    <Row>
                        <Col>
                            <h1 className="fancy">Edit Tribute Pages</h1>
                            <p>
                                This section allows you to edit the pages on your tribute site. Each page is a new tab below. 
                                To modify your site, just click on a tab and fill out the fields below. 
                                Then, click the Save Page button and your changes will be saved to your site for everyone to see!
                            </p>

                            <Tabs activeKey={currentTab} id="pagesTabs" className="mb-6" onSelect={tabSelect}>
                                <Tab eventKey="homePage" title="Home">
                                    <h2>Home</h2>
                                    <p>
                                        In the first field below, please enter the text you'd like to display in the main menu for the home page. 
                                        In the second field, please enter the content that you would like to see on the home page. 
                                    </p>
                                    
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="homePageTitle">
                                                <Form.Label>Page Title*</Form.Label>
                                                <Form.Control type="text" required value={homePageTitle} placeholder="Home Page Title" onChange={e => setHomePageTitle(e.target.value)} />
                                                <Form.Text id="homePageHelpText" muted>
                                                    Text to Display in Main Menu (Maximum 10 Characters)
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <br />
                                            <Form.Group controlId="homePageContent">
                                                <Form.Label>Page Body*</Form.Label>
                                                <Editor id="homePageContentInput"
                                                    apiKey="29d5nj6m4r5cyfynflx1jszwyjxk5g6kt7app047k1hzbs6o"
                                                    initialValue={initialHomePageContent}
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image', 
                                                            'charmap print preview anchor help',
                                                            'searchreplace visualblocks',
                                                            'insertdatetime media table paste wordcount'
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | help'
                                                    }}
                                                    onEditorChange={handleHomePageChange}
                                                />
                                            </Form.Group>
                                            <br />
                                        </Col>
                                    </Row>

                                    <Button className="tributeButton" onClick={e => savePageData('home')}>Save Page</Button>
                                </Tab>
                                <Tab eventKey="photoAlbum" title="Photo Album">
                                    <h2>Photo Album</h2>
                                    <p>
                                        In the first field below, please enter the text you'd like to display in the main menu for the photos page. 
                                        The Upload Your Album Photos section allows you to upload photos from your computer to add to your website. 
                                        Finally, the Your Uploaded Album Photos show you the images you have already uploaded.
                                    </p>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="photoAlbumTitle">
                                                <Form.Label>Page Title*</Form.Label>
                                                <Form.Control type="text" required value={photoAlbumPageTitle} placeholder="Photo Album Page Title" onChange={e => setPhotoAlbumPageTitle(e.target.value)} />
                                                <Form.Text id="photoAlbumPageHelpText" muted>
                                                    Text to Display in Main Menu (Maximum 10 Characters)
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <br />
                                    <Row>
                                        <Col>
                                            <Form.Label>Upload Images</Form.Label>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                            <Form.Text id="photoAlbumUploadHelpText" muted>
                                                Only *.jpeg and *.png images will be accepeted.
                                            </Form.Text>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Button className="tributeButton" onClick={e => savePageData('photoAlbum')}>Save Page</Button>

                                    <br /><br />
                                    <Row>
                                        <Col>
                                            <strong>Existing Photos</strong>
                                            {
                                                (photoAlbumPhotos && photoAlbumPhotos.length > 0) ?
                                                    <Table striped hover responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Photo</th>
                                                                <th colSpan="3" className="center"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { 
                                                                photoAlbumPhotos.map((d, i) => (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <Image className="photoAlbumPhoto" src={d.url} fluid />
                                                                        </td>
                                                                        <td className="align-middle"><Button className="noButton" variant="link" onClick={e => { viewEnlargedImage(d) }}>View Enlarged Image</Button></td>
                                                                        <td className="align-middle"><Button className="noButton" variant="link" onClick={e => { editComments(d)}}>Edit Comments</Button></td>
                                                                        <td className="align-middle"><Button className="noButton" variant="link" onClick={e => { openDeletePhotoModal(d) }}>Delete</Button></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                :
                                                    <div>
                                                        Every website looks better with some photos! Use the file upload area above to add photos of your loved one.<br /><br />
                                                    </div>
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="guestBook" title="Guest Book">
                                    <h2>Guest Book</h2>
                                    <p>
                                        In the field below, please enter the text you'd like to display in the main menu for the guestbook.
                                        As people sign your guest book, you will see what they have entered below. We give you the ability 
                                        to edit or delete guest book posts added to your site from this page. Just click Edit or Delete to modify each entry. 
                                    </p>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="guestBookTitle">
                                                <Form.Label>Page Title*</Form.Label>
                                                <Form.Control type="text" required value={guestBookPageTitle} placeholder="Guest Book Page Title" onChange={e => setGuestBookPageTitle(e.target.value)} />
                                                <Form.Text id="guestBookPageHelpText" muted>
                                                    Text to Display in Main Menu (Maximum 10 Characters)
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Button className="tributeButton" onClick={e => savePageData('guestBook')}>Save Page</Button>
                                    <br /><br />
                                    <Row>
                                        <Col>
                                            <strong>Existing Guest Book Entries</strong>
                                            {
                                                (guestBookEntries && guestBookEntries.length > 0) ?
                                                    <Table striped hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Name</th>
                                                                <th>Entry</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { 
                                                                guestBookEntries.map((d, i) => (
                                                                    <tr key={i}>
                                                                        <td>{d.date}</td>
                                                                        <td>{d.name}</td>
                                                                        <td>{d.comment}</td>
                                                                        <td className="align-middle"><Button className="noButton" variant="link" onClick={e => { openDeleteGuestBookEntryModal(d) }}>Delete</Button></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                :
                                                    <p>Sorry, but your site currently has no guest book entries yet.</p>
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="extraPage1" title="Extra Page 1">
                                    <h2>Extra Page 1</h2>
                                    <p>
                                        In the first field below, please enter the text you'd like to display in the main menu for this extra page. 
                                        In the second field, please enter the content that you would like to see on the extra page.
                                    </p>
                                    <p>
                                        This is an extra page on your tribute website. Name it and use it however you'd like.
                                    </p>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="ep1PageTitle">
                                                <Form.Label>Page Title*</Form.Label>
                                                <Form.Control type="text" required value={ep1PageTitle} placeholder="Extra Page 1 Title" onChange={e => setEp1PageTitle(e.target.value)} />
                                                <Form.Text id="ep1PageHelpText" muted>
                                                    Text to Display in Main Menu (Maximum 10 Characters)
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <br />
                                            <Form.Group controlId="ep1PageContent">
                                                <Form.Label>Page Body*</Form.Label>
                                                <Editor id="ep1PageContentInput"
                                                    apiKey="29d5nj6m4r5cyfynflx1jszwyjxk5g6kt7app047k1hzbs6o"
                                                    initialValue={initialEp1PageContent}
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image', 
                                                            'charmap print preview anchor help',
                                                            'searchreplace visualblocks',
                                                            'insertdatetime media table paste wordcount'
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | help'
                                                    }}
                                                    onEditorChange={handleEp1PageChange}
                                                />
                                            </Form.Group>
                                            <br />
                                        </Col>
                                    </Row>

                                    <Button className="tributeButton" onClick={e => savePageData('ep1')}>Save Page</Button>
                                </Tab>
                                <Tab eventKey="extraPage2" title="Extra Page 2">
                                    <h2>Extra Page 2</h2>
                                    <p>
                                        In the first field below, please enter the text you'd like to display in the main menu for this extra page. 
                                        In the second field, please enter the content that you would like to see on the extra page.
                                    </p>
                                    <p>
                                        This is an extra page on your tribute website. Name it and use it however you'd like.
                                    </p>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="ep2PageTitle">
                                                <Form.Label>Page Title*</Form.Label>
                                                <Form.Control type="text" required value={ep2PageTitle} placeholder="Extra Page 2 Title" onChange={e => setEp2PageTitle(e.target.value)} />
                                                <Form.Text id="ep2PageHelpText" muted>
                                                    Text to Display in Main Menu (Maximum 10 Characters)
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <br />
                                            <Form.Group controlId="ep2PageContent">
                                                <Form.Label>Page Body*</Form.Label>
                                                <Editor id="ep2PageContentInput"
                                                    apiKey="29d5nj6m4r5cyfynflx1jszwyjxk5g6kt7app047k1hzbs6o"
                                                    initialValue={initialEp2PageContent}
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image', 
                                                            'charmap print preview anchor help',
                                                            'searchreplace visualblocks',
                                                            'insertdatetime media table paste wordcount'
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | help'
                                                    }}
                                                    onEditorChange={handleEp2PageChange}
                                                />
                                            </Form.Group>
                                            <br />
                                        </Col>
                                    </Row>

                                    <Button className="tributeButton" onClick={e => savePageData('ep2')}>Save Page</Button>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                    <AlertModal 
                        showModal={showModal} 
                        title={alertTitle}
                        body={alertBody}
                        buttonText="Ok"
                        okMethod={closeModal}
                        fullScreen={fullScreenModal}
                    />

                    <ConfirmModal 
                        fullscreen={false}
                        showModal={showDeletePhotoModal}
                        title={"Confirm Delete Photo"}
                        body={
                            <div>
                                Are you sure you wnat to delete this photo? This will also delete any comments users have made on this photo. Note: This operation cannot be reversed.
                            </div>
                        }
                        confirmText="Yes"
                        confirmMethod={confirmDeletePhoto}
                        cancelText="Cancel"
                        cancelMethod={e => { setShowDeletePhotoModal(false) }}
                    />

                    <ConfirmModal 
                        fullscreen={false}
                        showModal={showDeleteGuestBookEntryModal}
                        title={"Confirm Delete Guest Book Entry"}
                        body={
                            <div>
                                Are you sure you wnat to delete this guest book entry? Note: This operation cannot be reversed.
                            </div>
                        }
                        confirmText="Yes"
                        confirmMethod={confirmDeleteGuestBookEntry}
                        cancelText="Cancel"
                        cancelMethod={e => { setShowDeleteGuestBookEntryModal(false) }}
                    />

                    <AlertModal 
                        fullScreen={true}
                        showModal={showPhotoCommentsModal}
                        title={"Edit Photo Comments"}
                        body={
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Image src={photoCommentsPhoto?.url} className="bigImage" fluid />
                                    </Col>
                                    <Col>
                                        {
                                        (photoComments && photoComments.length > 0) ?
                                            <div><br /><br /><h3>Photo Comments</h3></div>
                                        :
                                            ""
                                        }

                                        {
                                        (photoComments && photoComments.length > 0) ?
                                        photoComments.map((c) =>
                                            <Row key={c.id}>
                                                <Col>
                                                    <div className="template1PhotoCommentText">
                                                        {c.comment}
                                                    </div>
                                                    <div className="template1PhotoCommentByline">
                                                        - {c.name} - {c.date}
                                                    </div>
                                                    <br /><br />
                                                </Col>
                                                <Col>
                                                    <Button className="tributeButton deleteButton" onClick={e => { confirmDeleteComment(c, e) }}>Delete</Button>
                                                </Col>
                                            </Row>
                                        )
                                        : <div>There are no comments for this photo.</div>
                                        }
                                        <br />
                                    </Col>
                                </Row>
                            </Container>
                        }
                        buttonText="Close"
                        okMethod={e => { setShowPhotoCommentsModal(false) }}
                    />
                </Container>
            );
        }
    }
    
    return (
        <div>
            { renderContent() }
        </div>
    );
}