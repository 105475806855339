import React from 'react';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import { useHistory, Link } from "react-router-dom";

const Styles = styled.div`

`;

export const SubNav = (props) => {
    let history = useHistory();

    const selectSubnavTab = (key) => {
        if (key === "myTributes") {
            history.push("/secure/list-tributes");
        } else {
            history.push("/secure/profile");
        }
    }

    return (
        <Styles>
            <Container>
                <Row>
                    <Col>
                        <Tabs activeKey={props.subnavTabSelect} id="subnavTabs" onSelect={selectSubnavTab}>
                            <Tab eventKey="myTributes" title="My Tributes">
                                {
                                props.showListingLink ? 
                                    <p><Link to="/secure/list-tributes">Go to Tribute Listing Home</Link></p>
                                : 
                                    ""
                                }
                                {props.children}
                            </Tab>
                            <Tab eventKey="myProfile" title="My Profile">
                                {props.children}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </Styles>
    )
}