import React from 'react';
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom';

export const SearchResults = (props) => {
    const { query } = useParams();
    
    const searchClient = algoliasearch('CTDH1T663D', '4cb3afeb57d5d18be9d2861b683bee11');

    const Hits = ({ hits }) => (
        <Row xs={1} sm={2} lg={4}>
            {hits.map(hit => (
                <Col key={hit.objectID}><Link to={"/remember/" + hit.tributeUrl}>{hit.firstName} {hit.lastName}</Link></Col>
            ))}
        </Row>
    );
    
    const CustomHits = connectHits(Hits);

    return (
        <Container>
            <h1>Search Results</h1>
            <p>Below are the search results for <strong><em>"{query}"</em></strong>.</p>
            <InstantSearch searchClient={searchClient} indexName="prod-tribute-memorials">
                <SearchBox defaultRefinement={query} /><br />
                <CustomHits />
            </InstantSearch>
        </Container>
    );
}