import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDj3mEtXSKv_wcI8laDz0IEWf6JYFidqgY",
  authDomain: "tribute-memorials.firebaseapp.com",
  projectId: "tribute-memorials",
  storageBucket: "tribute-memorials.appspot.com",
  messagingSenderId: "132591475853",
  appId: "1:132591475853:web:ef57ffa9f5df1b508416c4",
  measurementId: "G-17SS4M21FX"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firestoreDb = firebase.firestore();

export const cloudStorage = firebase.storage();