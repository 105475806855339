import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Redirect, useHistory } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/auth';

export const VerifyEmail = (props) => {
    const [message, setMessage] = useState("");
    const {currentUser} = props;
    let history = useHistory();
    const resendEmailVerification = () => {
        firebase.auth().currentUser.sendEmailVerification().then(() => {
            setMessage("Email verification message has been sent.");
        });
    }

    useEffect(() => {
        if (currentUser.emailVerified) {
            history.push("/secure/list-tributes");
        }
    }, [currentUser, history]);
    
    return (
        (currentUser !== null) ?
            <Container>
                <Row>
                    <Col>
                        <h1>Verify Email Address</h1>
                        <p>
                            In order to continue using Tribute Memorials, you must first verify your email address. We have sent you an email at the follow address: <strong>{currentUser.email}</strong>. 
                        </p>
                        <p>
                            You should receive this email in the next few minutes. If you do not see it, please check your spam folder.
                        </p>
                        <p>
                            If you do not receive the verification email, you may click the button below to resend the email verification message.
                        </p>
                        {message !== "" ?
                            <p className="red">
                                {message}
                            </p>
                        :
                            <p>
                                <Button className="tributeButton" onClick={resendEmailVerification}>Resend Email Verification</Button>
                            </p>
                        }
                    </Col>
                </Row>
            </Container>
        :
            <Redirect to={{ pathname: '/secure/signin' }} />
    );
}