import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firestoreDb } from '../../firebase/firebaseConfiguration';
import { ConfirmModal } from '../../components/ConfirmModal';

export const ListTributes = (props) => {
    const {currentUser} = props;
    const [tributeSites, setTributeSites] = useState(null);
    const [siteToDelete, setSiteToDelete] = useState(null);
    const [showModal, setShowModal] = useState(false);
    let history = useHistory();

    firebase.auth().onAuthStateChanged((user) => {
        if (!user || (user && user.isAnonymous)) {
            history.push("/secure/signin");
        }
    });

    useEffect(() => {
        if (currentUser) {
            if (!currentUser.emailVerified) {
                history.push("/secure/verify-email");
            } else {
                let tsites = [];
                const tributeSiteCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites`);
                tributeSiteCollection.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        let d = doc.data();
                        d.id = doc.id;
                        tsites.push(d);
                    });

                    setTributeSites(tsites);
                    if (tsites.length === 0) {
                        history.push("/secure/edit-tribute");
                    }
                });
            }
        }
        
    }, [currentUser, history]);

    const fetchTributeSites = () => {
        let tsites = [];
        const tributeSiteCollection = firestoreDb.collection(`users/${currentUser.uid}/tributesites`);
        tributeSiteCollection.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let d = doc.data();
                d.id = doc.id;
                tsites.push(d);
            });

            setTributeSites(tsites);
            if (tsites.length === 0) {
                history.push("/secure/edit-tribute");
            }
        });
    }

    const openDeleteSiteModal = (siteId) => {
        setSiteToDelete(siteId);
        setShowModal(true);
    }

    const deleteSite = (siteId) => {
        if (currentUser) {    
            const tributeSiteDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${siteId}`);
            tributeSiteDocument.delete()
            .then(() => {
                setShowModal(false);
                fetchTributeSites();
            })
            .catch((error) => console.log("There was an error deleting the tribute site in the store: ", error));
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const renderContent = () => {
        if (currentUser === null) {
            <Container>Loading data...please wait.</Container>
        } else {
            if (tributeSites === null) {
                return ("Loading...");
            } else if (tributeSites && tributeSites.length > 0) {
                return (
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="fancy">Manage Your Tribute Sites</h1>
                                <p>
                                    From this page, you can manage all of your tribute websites. Click the edit icon to begin modifying a tribute site. If you would like to remove a tribute site, please click the trash can icon.
                                </p>
                                <p>
                                    Finally, if you'd like to create a new tribute site, just click the Create Tribute Site button. You can easily create multiple tribute sites with just a few clicks of a button.
                                </p>

                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Tribute Name</th>
                                            <th colSpan="5" className="center"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tributeSites.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.tributeUrl}</td>
                                                <td>View Site</td>
                                                <td><Link to={location => `/secure/edit-tribute/${d.id}`}>Edit Site Setting</Link></td>
                                                <td><Link to={location => `/secure/select-template/${d.id}`}>Select Template</Link></td>
                                                <td><Link to={location => `/secure/edit-pages/${d.id}`}>Edit Pages</Link></td>
                                                <td><Button className="noButton" variant="link" onClick={e => { openDeleteSiteModal(d.id) }}>Delete</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className="tributeButton" onClick={e => { history.push("/secure/edit-tribute") }}>Create New Tribute Site</Button>
                            </Col>
                        </Row>
                        <ConfirmModal showModal={showModal} 
                            fullscreen={false}
                            title="Delete Tribute Site?"
                            body="Are you sure you want to delete this tribute site?"
                            confirmText="Delete Site"
                            confirmMethod={e => { deleteSite(siteToDelete) } }
                            cancelText="Cancel"
                            cancelMethod={closeModal}
                        />
                    </Container>
                );
            }
        }
    }
    
    return (
        <div>
            { renderContent() }
        </div>
    );
}