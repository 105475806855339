import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/auth';

export const Profile = (props) => {
    const [message, setMessage] = useState("");
    const {currentUser} = props;

    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [changePasswordMessage, setChangePasswordMessage] = useState("");

    useEffect(() => {
        if (currentUser !== null) {
            setDisplayName(currentUser.displayName);
            setEmail(currentUser.email);
        }
    }, [currentUser]);

    firebase.auth().onAuthStateChanged((user) => {
    });

    const changePassword = () => {
        setIsChangePassword(true);
    }

    const cancelSaveNewPassword = () => {
        setIsChangePassword(false);
    }

    const saveNewPassword = () => {
        if (newPassword === "" || newPassword.length < 8) {
            setChangePasswordMessage("New password field must be at least 8 characters.");
        } else if (newPasswordConfirm === "" || newPasswordConfirm.length < 8) {
            setChangePasswordMessage("Confirm new password field must be at least 8 characters.");
        } else if (newPassword !== newPasswordConfirm) {
            setChangePasswordMessage("New password and confirm new password fields must match and they do not.")
        } else {
            currentUser.updatePassword(newPassword).then(() => {
                setMessage("Your password has been successfully updated.");
                setIsChangePassword(false);
            }).catch((error) => {
                console.log(error);
                setChangePasswordMessage("There was an error changing your password. Please try again later.");
            });
        }
    }

    const saveDisplayName = () => {
        if (displayName === "") {
            setMessage("Your display name cannot be empty. Please try again.");
        } else {
            currentUser.updateProfile({
                displayName: displayName
            }).then(() => {
                setMessage("Your display name has been updated.");
            }).catch((error) => {
                console.log(error);
                setMessage("There was an error updating your display name.");
            });
        } 
    }
    
    return (
        (currentUser !== null) ?
            <Container>
                <Row>
                    <Col>
                        <h1>My Profile</h1>
                        <p>Below is a list of all of the fields that make up your user profile.</p>
                        {message !== "" ? <p className="red">{message}</p> : ""}

                        <p><strong>Display Name</strong></p>
                        <Form.Group controlId="displayName" className="mb-3">
                            <Form.Control type="text" placeholder="Enter a display name" value={displayName} onChange={e => setDisplayName(e.target.value)} />
                        </Form.Group>

                        <Button className="tributeButton" onClick={saveDisplayName}>Save Display Name</Button>
                        <br /><br />

                        <p><strong>Email Address: </strong>{email}</p>
                        
                        {(isChangePassword) ?
                            <div>
                                <p><strong>Change Password</strong></p>
                                {(changePasswordMessage !== "") ? <p className="red">{changePasswordMessage}</p> : ""}
                                <Form.Group controlId="newPassword" className="mb-3">
                                    <Form.Control type="password" placeholder="Enter your new password" onChange={e => setNewPassword(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="confirmNewPassword" className="mb-3">
                                    <Form.Control type="password" placeholder="Confirm your new password" onChange={e => setNewPasswordConfirm(e.target.value)} />
                                </Form.Group>
                                <Button className="tributeButton" onClick={saveNewPassword}>Save New Password</Button>
                                <Button className="tributeButton leftMargin20" onClick={cancelSaveNewPassword}>Cancel</Button>
                            </div>
                        :
                            <p><Button variant="link" className="noButton" onClick={changePassword}>Change Password</Button></p>
                        }
                    </Col>
                </Row>
            </Container>
        :
            <Redirect to={{ pathname: '/secure/signin' }} />
    );
}