import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/auth';

export const AuthAction = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [mode] = useState(params.get('mode'));
    const [oobCode] = useState(params.get('oobCode'));
    const [verifyMessage, setVerifyMessage] = useState("We are in the process of verifying your email address. Please wait...");

    const [resetPasswordEmail, setResetPasswordEmail] = useState("");
    const [resetPasswordPassword, setResetPasswordPassword] = useState("");
    const [resetPasswordPasswordConfirm, setResetPasswordPasswordConfirm] = useState("");
    const [resetPasswordMessage, setResetPasswordMessage] = useState("");
    const [isPasswordReset, setIsPasswordReset] = useState(false);

    let history = useHistory();

    useEffect(() => {
        // Handle the user management action.
        switch (mode) {
            case 'resetPassword':
                // Display reset password handler and UI.
                firebase.auth().verifyPasswordResetCode(oobCode).then((email) => {
                    setResetPasswordEmail(email);
                }).catch((error) => {
                    setResetPasswordMessage("Your reset password token is invalid. It has either already been used by you or there was some other error with it. If you still need to reset your password, please go back to the sign in page and try again.");
                });
                break;
            case 'recoverEmail':
                // Display email recovery handler and UI.
                break;
            case 'verifyEmail':
                // Display email verification handler and UI.
                firebase.auth().applyActionCode(oobCode).then((resp) => {
                    setVerifyMessage("Your email address has been verified. Please click the button below to continue.");
                }).catch((error) => {
                    setVerifyMessage("This verification code was already used. Please reverify your email address.");
                });
                break;
            default:
                // Error: invalid mode.
        }
    }, [history, mode, oobCode]);

    firebase.auth().onAuthStateChanged((user) => {});

    const continueAfterEmailVerification = () => {
        window.location.href = "/secure/list-tributes";
    }

    const resetPasswordConfirm = () => {
        setResetPasswordMessage("");
        if (resetPasswordEmail === "") {
            setResetPasswordMessage("There is no email address associated with the link you passed in. Please try resetting your password again.");
        } else if (resetPasswordPassword === "" || resetPasswordPassword.length < 8) {
            setResetPasswordMessage("Your password field must be filled in and at least 8 characters long.");
        } else if (resetPasswordPasswordConfirm === "" || resetPasswordPasswordConfirm.length < 8) {
            setResetPasswordMessage("Your password confirm field must be filled in and at least 8 characters long.");
        } else if (resetPasswordPassword !== resetPasswordPasswordConfirm) {
            setResetPasswordMessage("Your password and confirm password fields must match. Please retype your new password in both fields again.");
        } else {
            // Save the new password.
            firebase.auth().confirmPasswordReset(oobCode, resetPasswordPassword).then((resp) => {
                setIsPasswordReset(true);
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const continueAfterResetPassword = () => {
        firebase.auth().signInWithEmailAndPassword(resetPasswordEmail, resetPasswordPassword).then((r) => {
            history.push("/secure/list-tributes");
        }).catch((error) => {
            console.log(error);
        });
        /*
        let user = firebase.auth().currentUser;
        if (user && !user.isAnonymous && user.emailVerified) {
            history.push("/secure/list-tributes");
        } else {
            history.push("/secure/signin");
        }
        */
    }

    const returnContent = () => {
        if (mode === "") {
            return "";
        } else if (mode === "verifyEmail") {
            return (
                <Container>
                    <Row>
                        <Col>
                            <h1>Verifying Email Address</h1>
                            <p>{verifyMessage}</p>
                            <Button className="tributeButton" onClick={continueAfterEmailVerification}>Continue &gt;</Button>
                        </Col>
                    </Row>
                </Container>
            );
        } else if (mode === "resetPassword") {
            return (
                <Container>
                    <Row>
                        <Col>
                            <h1>Reset Password</h1>
                            {(resetPasswordMessage !== "") ? <p className="red">{resetPasswordMessage}</p> : ""}
                            {
                            !isPasswordReset ?
                                <div>
                                    <p>To reset the password on your account below, please enter your new password into the 2 fields below.</p>
                                    
                                    <p><strong>Email Address: </strong>{resetPasswordEmail}</p>
                                    <Form.Group controlId="resetPassword" className="mb-3">
                                        <Form.Control type="password" placeholder="Enter new password" onChange={e => setResetPasswordPassword(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="resetPasswordConfirm" className="mb-3">
                                        <Form.Control type="password" placeholder="Confirm new password" onChange={e => setResetPasswordPasswordConfirm(e.target.value)} />
                                    </Form.Group>
                                    <Button className="tributeButton" onClick={resetPasswordConfirm}>Reset Password &gt;</Button>
                                </div>
                            :
                                <div>
                                    <p>Your password has been successfully reset. Please click the button below to continue.</p>
                                    <Button className="tributeButton" onClick={continueAfterResetPassword}>Continue &gt;</Button>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            );
        }
    }

    return (
        <div>
            { returnContent() }
        </div>
    );
}