import React, { useState } from "react";
import firebase from 'firebase/app';
import 'firebase/auth';
import './firebase/firebaseConfiguration';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Error404 } from "./pages/Error404";
import { MainNav } from "./layout/MainNav";
import { Footer } from "./layout/Footer";
import { SignIn } from './pages/SignIn';
import { ListTributes } from './pages/secure/ListTributes';
import { EditTribute } from './pages/secure/EditTribute';
import { SelectTemplate } from './pages/secure/SelectTemplate';
import { EditPages } from './pages/secure/EditPages';
import { UserSite } from './pages/UserSite';
import { VerifyEmail } from './pages/secure/VerifyEmail';
import { AuthAction } from './components/AuthAction';
import { Profile } from './pages/secure/Profile';
import { SearchResults } from "./pages/SearchResults";
import { SubNav } from './layout/SubNav';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState(null);

  firebase.auth().onAuthStateChanged((user) => {
    if (user && !user.isAnonymous) {
      setCurrentUser(user);
      setLoggedIn(true);
    } else if (!user) {
      setCurrentUser(null);
      setLoggedIn(false);
      firebase.auth().signInAnonymously()
        .then(() => console.log("User signed in anonymously"))
        .catch((error) => console.log("There was an error signing in anonomusly.", error));
    }
  });

  return(
    <Router>
      <Switch>
        <Route exact path="/">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <Home />
          <Footer />
        </Route>

        <Route exact path="/secure/signin">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SignIn setCurrentUser={setCurrentUser} setLoggedIn={setLoggedIn} setDisplayName={setDisplayName} />
          <Footer />
        </Route>

        <Route exact path="/secure/verify-email">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <VerifyEmail currentUser={currentUser} />
          <Footer />
        </Route>

        <Route exact path="/secure/profile">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SubNav subnavTabSelect="myProfile" showListingLink={false}>
            <Profile currentUser={currentUser} />
          </SubNav>
          <Footer />
        </Route>

        <Route exact path="/secure/list-tributes">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SubNav subnavTabSelect="myTributes" showListingLink={false}>
            <ListTributes currentUser={currentUser} />
          </SubNav>
          <Footer />
        </Route>

        <Route exact path="/secure/edit-tribute">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SubNav subnavTabSelect="myTributes" showListingLink={true}>
            <EditTribute currentUser={currentUser} />
          </SubNav>
          <Footer />
        </Route>

        <Route path="/secure/edit-tribute/:id">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SubNav subnavTabSelect="myTributes" showListingLink={true}>
            <EditTribute currentUser={currentUser} />
          </SubNav>
          <Footer />
        </Route>

        <Route path="/secure/select-template/:id">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SubNav subnavTabSelect="myTributes" showListingLink={true}>
            <SelectTemplate currentUser={currentUser} />
          </SubNav>
          <Footer />
        </Route>

        <Route path="/secure/edit-pages/:id">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SubNav subnavTabSelect="myTributes" showListingLink={true}>
            <EditPages currentUser={currentUser} />
          </SubNav>
          <Footer />
        </Route>

        <Route path="/secure/auth-action">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <AuthAction currentUser={currentUser} />
          <Footer />
        </Route>

        <Route path="/remember/:siteName">
          <UserSite />
        </Route>

        <Route path="/search/:query">
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <SearchResults />
          <Footer />
        </Route>

        <Route>
          <MainNav loggedInState={loggedIn} loggedInUser={currentUser} setCurrentUser={setCurrentUser} displayName={displayName} />
          <Error404 />
          <Footer />
        </Route>
        
      </Switch>
    </Router>
  );
}

export default App;