import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';


const Styles = styled.div`
    .footerContainer {
        background-color: rgb(8, 63, 68);
        color: #FFFFFF;
        margin-top: 50px;
        padding-top: 20px;
        padding-bottom: 10px;
        text-align: center;
        line-height: 0px;
        font-size: 12px;
    }

    .footerContainer a {
        color: #FFFFFF;
        line-height: 0px;
        text-decoration: underline;
    }

    .footerContainer a:hover {
        color: #FFFFFF;
        line-height: 0px;
        text-decoration: none;
    }

    .bottomFooter {
        background-color: #222222;
        color: #FFFFFF;
        font-size: 12px;
        padding: 20px 0 20px 0;
    }
`;

export const Footer = (props) => {
    return (
        <Styles>
            <Container fluid className="footerContainer">
                <p>Free Memorial Websites provided by:</p>
                <p><a href="https://www.memorials.com" target="_blank" rel="noreferrer">Memorials.com</a></p>
                <p>15605 S Padre Island Dr - Corpus Christi, Texas 78418 - <a href="mailto:tribute@memorials.com">tribute@memorials.com</a></p>
                <p>
                    <a href="https://www.memorials.com/Terms-&-Conditions-information.php" target="_blank" rel="noreferrer">Terms & Conditions</a> | <a href="https://www.memorials.com/Privacy-Policy-information.php" target="_blank" rel="noreferrer">Privacy Policy</a> | © 2002-2021 - Memorials.com - All Rights Reserved
                </p>
            </Container>
        </Styles>
    )
}