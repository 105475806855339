import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tab, Tabs, Image, Button, Form } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import template1Img from '../../assets/templates/1.png';
//import template2Img from '../../assets/templates/2.png';
//import template3Img from '../../assets/templates/3.png';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firestoreDb, cloudStorage } from '../../firebase/firebaseConfiguration';
import { ColorPicker } from '../../components/ColorPicker';
import { AlertModal } from '../../components/AlertModal';
import { getTimestampString } from '../../components/TimeStamp';

export const SelectTemplate = (props) => {
    const {currentUser} = props;
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [currentTab, setCurrentTab] = useState("selectTemplate");
    const [showNewTemplateSelectedMessage, setShowNewTemplateSelectedMessage] = useState(false);
    const { id } = useParams();
    let history = useHistory();

    // User settings
    const [settingErrorText, setSettingErrorText] = useState("");
    const [headerTextColor, setHeaderTextColor] = useState({r: 255, g: 255, b: 255, a: 1});
    const [headerHeadingColor, setHeaderHeadingColor] = useState({r: 255, g: 255, b: 255, a: 1});
    const [headerButtonColor, setHeaderButtonColor] = useState({r: 46, g: 130, b: 159, a: 1});
    const [headerButtonTextColor, setHeaderButtonTextColor] = useState({r: 255, g: 255, b: 255, a: 1});

    const [mainMenuTextColor, setMainMenuTextColor] = useState({r: 255, g: 255, b: 255, a: 1});
    const [mainMenuBackgroundColor, setMainMenuBackgroundColor] = useState({r: 255, g: 255, b: 255, a: 0});
    const [mainMenuTextColorHover, setMainMenuTextColorHover] = useState({r: 255, g: 255, b: 255, a: 1});
    const [mainMenuBackgroundColorHover, setMainMenuBackgroundColorHover] = useState({r: 255, g: 255, b: 255, a: 1});
    
    const [headerLinesColor, setHeaderLinesColor] = useState({r: 192, g: 192, b: 192, a: .35});
    const [headerMainImage, setHeaderMainImage] = useState("");

    const [sloganText, setSloganText] = useState("");
    const [sloganTextColor, setSloganTextColor] = useState({r: 255, g: 255, b: 255, a: 1});

    const [showSelectImageModal, setShowSelectImageModal] = useState(false);
    const [stockPhotos, setStockPhotos] = useState(null);
    const [tributeSitePhotos, setTributeSitePhotos] = useState(null);

    const [tributeSiteUrl, setTributeSiteUrl] = useState("");
    
    firebase.auth().onAuthStateChanged((user) => {
        if (!user || (user && user.isAnonymous)) {
            history.push("/secure/signin");
        }
    });

    useEffect(() => {
        if (currentUser) {
            if (!currentUser.emailVerified) {
                history.push("/secure/verify-email");
            } else {
                if ((!selectedTemplate || selectedTemplate === null) && id !== null) {
                    // Look up the user's site and get the selected template
                    const tributeSiteDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}`);
                    tributeSiteDocument.get().then((doc) => {
                        if (doc.exists) {
                            let data = doc.data();
                            setTributeSiteUrl(data.tributeUrl);
                            if (data.templateSettings) {
                                if (data.templateSettings.headerHeadingColor) {
                                    setHeaderHeadingColor(data.templateSettings.headerHeadingColor);
                                }
                                if (data.templateSettings.headerTextColor) {
                                    setHeaderTextColor(data.templateSettings.headerTextColor);
                                }
                                if (data.templateSettings.headerButtonColor) {
                                    setHeaderButtonColor(data.templateSettings.headerButtonColor);
                                }
                                if (data.templateSettings.headerButtonTextColor) {
                                    setHeaderButtonTextColor(data.templateSettings.headerButtonTextColor);
                                }

                                if (data.templateSettings.mainMenuTextColor) {
                                    setMainMenuTextColor(data.templateSettings.mainMenuTextColor);
                                }
                                if (data.templateSettings.mainMenuBackgroundColor) {
                                    setMainMenuBackgroundColor(data.templateSettings.mainMenuBackgroundColor);
                                }
                                if (data.templateSettings.mainMenuTextColorHover) {
                                    setMainMenuTextColorHover(data.templateSettings.mainMenuTextColorHover);
                                }
                                if (data.templateSettings.mainMenuBackgroundColorHover) {
                                    setMainMenuBackgroundColorHover(data.templateSettings.mainMenuBackgroundColorHover);
                                }
                                if (data.templateSettings.headerLinesColor) {
                                    setHeaderLinesColor(data.templateSettings.headerLinesColor);
                                }
                                if (data.templateSettings.headerMainImage) {
                                    setHeaderMainImage(data.templateSettings.headerMainImage);
                                }

                                if (data.templateSettings.sloganText) {
                                    setSloganText(data.templateSettings.sloganText);
                                }
                                if (data.templateSettings.sloganTextColor) {
                                    setSloganTextColor(data.templateSettings.sloganTextColor);
                                }
                            }

                            if (data.template && data.template != null) {
                                setSelectedTemplate(data.template);
                                setShowNewTemplateSelectedMessage(true);
                            }

                            // Get all of this site's tribute photos
                            let tphotos = [];
                            let tributePhotos = firestoreDb.collection(`users/${currentUser.uid}/tributesites/${id}/photos`);
                            tributePhotos.get().then((querySnapshot2) => {
                                let counter = 0;
                                querySnapshot2.forEach((doc2) => {
                                    counter++;
                                    let d = doc2.data();
                                    d.id = doc2.id;

                                    // Have to do this to get the correct url for this file
                                    const currentFileReference = cloudStorage.ref(`${d.path}`);
                                    currentFileReference.getDownloadURL().then((url) => {
                                        d.url = url;
                                        tphotos.push(d);
                                        if (counter === querySnapshot2.size) {
                                            setTributeSitePhotos(tphotos);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log("Can't get file's url: ", error);
                                    });
                                });
                            }).catch((error) => {
                                // Go to 404 page
                                console.log("Error 6");
                                history.push("/nositefound");
                            });

                            let tStockPhotos = [];
                            // Create a storage reference from our storage service
                            let storageRef = cloudStorage.ref();
                            let stockPhotosRef = storageRef.child('main/stockphotos');
                            stockPhotosRef.listAll().then((res) => {
                                let counter2 = 0;
                                res.items.forEach((itemRef) => {
                                    counter2++;
                                    const fRef = cloudStorage.ref(`${itemRef.fullPath}`);
                                    fRef.getDownloadURL().then((url) => {
                                        let d = {
                                            id: itemRef.fullPath,
                                            path: itemRef.fullPath,
                                            url: url,
                                            date: getTimestampString()[0],
                                            orderByDate: getTimestampString()[1]
                                        }
                                        
                                        tStockPhotos.push(d);

                                        if (counter2 === res.items.length) {
                                            setStockPhotos(tStockPhotos);
                                        }
                                    }).catch((error) => {
                                        console.log("Can't get file's url: ", error);
                                    });
                                });
                            }).catch((error) => {
                                // Uh-oh, an error occurred!
                            });
                        }
                    }).catch((error) => {
                        console.log("Error fetching data", error);
                    });
                } else if (id === null) {
                    history.push("/secure/list-tributes");
                }
            }
        }
        
    }, [currentUser, selectedTemplate, history, id]);

    const saveTemplate = (templateId) => {
        setSelectedTemplate(templateId);

        const tributeSiteDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}`);
        tributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                data.template = templateId;
                
                tributeSiteDocument.update(data).then(() => {
                    setShowNewTemplateSelectedMessage(true);
                }).catch((error) => { 
                    console.log("There was an error updating the tribute site with the selected template in the store: ", error)
                });
            }
        }).catch((error) => {
            console.log("Error fetching data for updating the template", error);
        });
    }

    const tabSelect = (key) => {
        if (key === "templateSettings") {
            setShowNewTemplateSelectedMessage(false);
        }
        setCurrentTab(key);
    }

    const setHeaderTextColorPicker = (color) => {
        setHeaderTextColor(color);
    }

    const setHeaderHeadingColorPicker = (color) => {
        setHeaderHeadingColor(color);
    }

    const setHeaderButtonColorPicker = (color) => {
        setHeaderButtonColor(color);
    }

    const setHeaderButtonTextColorPicker = (color) => {
        setHeaderButtonTextColor(color);
    }

    const setMainMenuTextColorPicker = (color) => {
        setMainMenuTextColor(color);
    }

    const setMainMenuBackgroundColorPicker = (color) => {
        setMainMenuBackgroundColor(color);
    }

    const setMainMenuTextColorHoverPicker = (color) => {
        setMainMenuTextColorHover(color);
    }

    const setMainMenuBackgroundColorHoverPicker = (color) => {
        setMainMenuBackgroundColorHover(color);
    }

    const setHeaderLinesColorPicker = (color) => {
        setHeaderLinesColor(color);
    }

    const setSloganTextColorPicker = (color) => {
        setSloganTextColor(color);
    }

    const viewSite = () => {
        window.open("/remember/" + tributeSiteUrl);
    }

    const saveSettings = () => {
        setSettingErrorText("");
        const tributeSiteDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}`);
        tributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                if (!data.templateSettings) {
                    data.templateSettings = {};
                }
                data.templateSettings.headerTextColor = headerTextColor;
                data.templateSettings.headerHeadingColor = headerHeadingColor;
                data.templateSettings.headerButtonColor = headerButtonColor;
                data.templateSettings.headerButtonTextColor = headerButtonTextColor;
                
                data.templateSettings.mainMenuTextColor = mainMenuTextColor;
                data.templateSettings.mainMenuBackgroundColor = mainMenuBackgroundColor;
                data.templateSettings.mainMenuTextColorHover = mainMenuTextColorHover;
                data.templateSettings.mainMenuBackgroundColorHover = mainMenuBackgroundColorHover;
                data.templateSettings.headerLinesColor = headerLinesColor;

                data.templateSettings.sloganText = sloganText;
                data.templateSettings.sloganTextColor = sloganTextColor;
                data.templateSettings.headerMainImage = headerMainImage;
                
                tributeSiteDocument.update(data).then(() => {
                    setSettingErrorText("Your template settings have been saved");
                }).catch((error) => { 
                    console.log("There was an error saving the template settings: ", error)
                });
            }
        }).catch((error) => {
            console.log("Error fetching data for updating the template settings", error);
        });
    }

    const saveMainImage = (photo) => {
        setHeaderMainImage(photo);
        const tributeSiteDocument = firestoreDb.doc(`users/${currentUser.uid}/tributesites/${id}`);
        tributeSiteDocument.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                if (!data.templateSettings) {
                    data.templateSettings = {};
                }

                data.templateSettings.headerMainImage = photo;
                tributeSiteDocument.update(data).then(() => {
                    setShowSelectImageModal(false);
                    setSettingErrorText("Your Header Main Image has been saved");
                }).catch((error) => { 
                    console.log("There was an error saving the main image: ", error)
                });
            }
        });
    }

    const printImages = (images) => {
        let text = "";
        if (images) {
            text = images.map((photo) =>
                <Col key={photo?.id} className="m-auto"><Image id={'photo' + photo?.id} className={(photo?.id === headerMainImage?.id) ? "imageMax d-block mx-auto img-fluid imageSelected" : "imageMax d-block mx-auto img-fluid"} src={photo?.url} onClick={e => saveMainImage(photo)} /></Col>
            );
        } else {
            text = <Col>There are no images in your photo gallery.</Col>
        }
        return text;
    }

    const RenderTemplateSettings = () => {
        if (selectedTemplate === 1) {
            return (
                <div>
                    <Row>
                        <Col><h3>Header</h3></Col>
                    </Row>
                    <Row xs={1} md={3}>
                        <Col className="bottomPadding">
                            <Form.Group controlId="headerHeadingColor">
                                <Form.Label>Header Heading Color</Form.Label>
                                <ColorPicker id="headerHeadingColor" selectedColor={headerHeadingColor} onChange={setHeaderHeadingColorPicker} />
                                <Form.Text id="headerHeadingHelpText" muted>
                                    The text color for your loved one's name.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="bottomPadding">
                            <Form.Group controlId="headerButtonColor">
                                <Form.Label>Header Button Color</Form.Label>
                                <ColorPicker id="headerButtonColor" selectedColor={headerButtonColor} onChange={setHeaderButtonColorPicker} />
                                <Form.Text id="headerHeadingHelpText" muted>
                                    The text color for your loved one's name.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="headerButtonTextColor">
                                <Form.Label>Header Button Text Color</Form.Label>
                                <ColorPicker id="headerHeadingColor" selectedColor={headerButtonTextColor} onChange={setHeaderButtonTextColorPicker} />
                                <Form.Text id="headerHeadingHelpText" muted>
                                    The text color for your loved one's name.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="d-none d-lg-block">
                        <Col><br /></Col>
                    </Row>
                    <Row xs={1} md={3}>
                        <Col className="bottomPadding">
                            <Form.Group controlId="headerTextColor">
                                <Form.Label>Header Text Color</Form.Label>
                                <ColorPicker id="headerTextColor" selectedColor={headerTextColor} onChange={setHeaderTextColorPicker} />
                                <Form.Text id="headerTextHelpText" muted>
                                    All text in the top header that is not the main menu.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="bottomPadding">
                            <Form.Group controlId="headerLinesColor">
                                <Form.Label>Header Lines Color</Form.Label>
                                <ColorPicker id="headerLinesColor" selectedColor={headerLinesColor} onChange={setHeaderLinesColorPicker} />
                                <Form.Text id="headerLinesColorHelpText" muted>
                                    Controls the color of the lines to the left and right of the main menu and the line under the website name.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="d-none d-lg-block">&nbsp;</Col>
                    </Row>
                    <Row xs={1} md={3}>
                        <Col className="bottomPadding">
                            <Form.Group controlId="headerMainImage">
                                <Form.Label>Header Main Image</Form.Label>
                                <div>
                                    <Image id="headerMainImage" src={headerMainImage?.url} fluid />
                                    <Button variant="link" className="noButton" onClick={e => setShowSelectImageModal(true)}>Select Image</Button>
                                </div>
                                <Form.Text id="headerLinesColorHelpText" muted>
                                    This is the main image that appears in the header of your site. Select a photo album image or one from our gallery. Image should be 2000 pixels wide and have a 3:2 aspect ratio.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="d-none d-lg-block">&nbsp;</Col>
                        <Col className="d-none d-lg-block">&nbsp;</Col>
                    </Row>
                    <Row className="d-none d-lg-block">
                        <Col><br /></Col>
                    </Row>
                    <Row>
                        <Col><h3>Slogan</h3></Col>
                    </Row>
                    <Row xs={1} md={2}>
                        <Col className="bottomPadding">
                            <Form.Group controlId="sloganText">
                                <Form.Label>Slogan Text</Form.Label>
                                <Form.Group controlId="sloganTextInput">
                                    <Form.Control type="text" placeholder="Enter your slogan text here" value={sloganText} onChange={e => setSloganText(e.target.value)} />
                                </Form.Group>
                                <Form.Text id="sloganTextHelpText" muted>
                                    Enter your message here. It will appear under your loved one's name.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="sloganTextColor">
                                <Form.Label>Slogan Text Color</Form.Label>
                                <ColorPicker id="sloganTextColor" selectedColor={sloganTextColor} onChange={setSloganTextColorPicker} />
                                <Form.Text id="sloganTextColorHelpText" muted>
                                    The text color for the slogan text.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col><br /></Col>
                    </Row>
                    <Row>
                        <Col><h3>Main Menu</h3></Col>
                    </Row>
                    <Row xs={1} md={2} lg={4}>
                        <Col className="bottomPadding">
                            <Form.Group controlId="mainMenuTextColor">
                                <Form.Label>Main Menu Text Color</Form.Label>
                                <ColorPicker id="mainMenuTextColor" selectedColor={mainMenuTextColor} onChange={setMainMenuTextColorPicker} />
                                <Form.Text id="mainMenuTextColorHelpText" muted>
                                    The text color of the main menu items.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="bottomPadding">
                            <Form.Group controlId="mainMenuBackgroundColor">
                                <Form.Label>Main Menu Background Color</Form.Label>
                                <ColorPicker id="mainMenuBackgroundColor" selectedColor={mainMenuBackgroundColor} onChange={setMainMenuBackgroundColorPicker} />
                                <Form.Text id="mainMenuBackgroundColorHelpText" muted>
                                    The background color used behind the main menu items.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="bottomPadding">
                            <Form.Group controlId="mainMenuTextColorHover">
                                <Form.Label>Main Menu Hover Text Color</Form.Label>
                                <ColorPicker id="mainMenuTextColorHover" selectedColor={mainMenuTextColorHover} onChange={setMainMenuTextColorHoverPicker} />
                                <Form.Text id="mainMenuTextColorHoverHelpText" muted>
                                    The text color of the main menu items when you hover over them with your mouse.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="mainMenuBackgroundColorHover">
                                <Form.Label>Main Menu Hover Background Color</Form.Label>
                                <ColorPicker id="mainMenuBackgroundColorHover" selectedColor={mainMenuBackgroundColorHover} onChange={setMainMenuBackgroundColorHoverPicker} />
                                <Form.Text id="mainMenuBackgroundColorHoverHelpText" muted>
                                    The background color used behind the main menu items when you hover over them with your mouse.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button className="tributeButton" onClick={saveSettings}>Save Settings</Button>
                        </Col>
                        <Col>
                            <Button className="tributeButton float-end" onClick={viewSite}>View Site</Button>
                        </Col>
                    </Row>

                    <AlertModal 
                        fullScreen={true}
                        showModal={showSelectImageModal}
                        title={"Select Main Site Image"}
                        body={
                            <div>
                                <p>Please select either one of our stock images or one of your photo album images from the list below.</p>
                                <p><strong>Your Photo Album Images</strong></p>
                                <div>
                                    {!tributeSitePhotos ? <p>You have no photo album images to choose from. Please upload some.</p> : <Container fluid><Row xs={1} sm={2} md={4} lg={6}>{printImages(tributeSitePhotos)}</Row></Container>}
                                </div>
                                <p><strong>Our Stock Images</strong></p>
                                <div>
                                    {!stockPhotos ? <p>There are no stock photos at this time. Please try again later.</p> : <Container fluid><Row xs={1} sm={2} md={4} lg={6}>{printImages(stockPhotos)}</Row></Container>}    
                                </div>
                            </div>
                        }
                        buttonText="Close"
                        okMethod={e => { setShowSelectImageModal(false) }}
                    />
                </div>
            );
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1 className="fancy">Site Template &amp; Settings</h1>
                    <p>
                        This page allows you to choose the site template that you would like to use to display your tribute website. 
                        First, choose the site template that you would like to use below in the Templates tab. 
                        Next, click the Template Settings table to fill out the settings that are listed for your chosen template. 
                        From time to time, we will upload new site templates, so please check back frequently.
                    </p>

                    <Tabs activeKey={currentTab} id="selectTemplatesTabs" className="mb-6" onSelect={tabSelect}>
                        <Tab eventKey="selectTemplate" title="Template">
                            <h2>Site Template</h2>
                            <p>
                                Click on a template thumbnail below to select that template for your site, or click on the "Preview this Template" 
                                link underneath the thumbnail to see a sample site for this template. Once you select a template, you will be able 
                                to customize various elements in the Template Settings tab.
                            </p>
                            
                            <Row xs={1} md={3}>
                                <Col className="bottomPadding">
                                    <p><strong>Template #1</strong></p>
                                    <Image id="template1" src={template1Img} className={selectedTemplate === 1 ? "selectedImage" : "clickableImage"} onClick={e => selectedTemplate !== 1 ? saveTemplate(1) : "" } fluid />
                                    { selectedTemplate !== 1 
                                    ?
                                        <Button variant="link" className="noButton" onClick={e => saveTemplate(1)}>Select Template #1</Button>
                                    :
                                        <strong>Template 1 Selected</strong>
                                    }
                                </Col>
                                {/*
                                <Col className="bottomPadding">
                                    <p><strong>Template #2</strong></p>
                                    <Image id="template2" src={template2Img} className={selectedTemplate === 2 ? "selectedImage" : "clickableImage"} onClick={e => selectedTemplate !== 2 ? saveTemplate(2) : "" } fluid />
                                    { selectedTemplate !== 2
                                    ?
                                        <Button variant="link" className="noButton" onClick={e => saveTemplate(2)}>Select Template #2</Button>
                                    :
                                        <strong>Template 2 Selected</strong>
                                    }
                                </Col>
                                <Col className="bottomPadding">
                                    <p><strong>Template #3</strong></p>
                                    <Image id="template3" src={template3Img} className={selectedTemplate === 3 ? "selectedImage" : "clickableImage"} onClick={e => selectedTemplate !== 3 ? saveTemplate(3) : "" } fluid />
                                    { selectedTemplate !== 3
                                    ?
                                        <Button variant="link" className="noButton" onClick={e => saveTemplate(3)}>Select Template #3</Button>
                                    :
                                        <strong>Template 3 Selected</strong>
                                    }
                                </Col>
                                */}
                            </Row>
                            { 
                            showNewTemplateSelectedMessage 
                            ?
                                <div>
                                    <br />
                                    <p><strong>You have successfully selected a site template. Now, head over to the <Button variant="link" className="noButton" onClick={e => tabSelect("templateSettings")}>Template Settings</Button> tab to modify it.</strong></p>
                                </div>
                            :
                                ""
                            }
                        </Tab>
                        <Tab eventKey="templateSettings" title="Template Settings">
                            <h2>Template Settings</h2>
                            {
                            selectedTemplate !== null ?
                                <div>
                                    <p>
                                        Below you can customize the settings of the template you selected.
                                    </p>
                                    {
                                    (settingErrorText !== "") ?
                                        <p className="red">{settingErrorText}</p>
                                    :
                                        ""
                                    }
                                    {RenderTemplateSettings()}
                                </div>
                            :
                                <p>Please select a template first then you can modify that template's settings.</p>
                            }

                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}